import React from 'react';
import ReactPaginate from 'react-paginate';
import Select from "react-select";

import './pagination.scss';

const OPTIONS = [
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 300, label: '300' }
]

export const Pagination = ({ totalCount, currentPage, pageSize, setCurrentPage, onChangePageSize }) => {
  const pageCount = Math.ceil(totalCount / pageSize);
  return (
    <div className="paginate">
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        initialPage={currentPage - 1}
        onPageChange={page => setCurrentPage(page.selected + 1)}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        previousLinkClassName="page-link"
        previousClassName="page-item"
        nextLinkClassName="page-link"
        nextClassName="page-item"
        breakLinkClassName="page-link"
        breakClassName="page-item"
        previousLabel="‹"
        nextLabel="›"
      />
      <div className="count">
        <div className="count-title">Строк на странице</div>

        <Select
          name="type"
          placeholder="Выберите клиента"
          className="react-select-container"
          classNamePrefix="react-select"
          options={OPTIONS}
          value={OPTIONS.find(val => pageSize === val)}
          defaultValue={[{ value: 10, label: '10' }]}
          onChange={val => onChangePageSize(val.value)}
        />

      </div>
    </div>
  );
};