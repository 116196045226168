import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat } from "utils/format";
import { useHistory } from "react-router-dom";
import { CheckCircle } from "react-feather";

import user from "assets/img/user.svg";

import "./ordersTable.scss";

export const CorrOrdersTable = ({ corrOrders }) => {
  const history = useHistory();
  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "number",
      text: "Номер корр",
      formatter: (cell, row) =>
        row.index ? `${row.order?.number}/${row.index}` : row.order?.number,
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      headerStyle: () => {
        return { width: "3%" };
      },
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "createdAt",
      text: "Дата корр.",
      formatter: (cell, row) => dateFormat(cell),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "date",
      text: "Дата реал.",
      formatter: (cell, row) => dateFormat(row.order?.date),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "customer",
      text: "Клиент",
      formatter: (cell, row) => (
        <span className="cute">{row.order?.customer}</span>
      ),
      headerStyle: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "4%" };
      },
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "4%" };
      },
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "admin",
      text: "Кто создал",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/sales/corrOrders/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={corrOrders}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
