import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import cellEditFactory from "react-bootstrap-table2-editor";
import Select from "react-select";

import {
  brandsSuggestion,
  getBrands,
  setBrand,
  updateBrand,
} from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";

import { tableColumns } from "./tableData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { CreateBrandModal } from "features/Brands/CreateBrandModal";
import { SelectSearch } from "components/SelectSearch";

import "./brands.scss";

const SORT_FIELDS = [
  {
    label: "Дата добавления ↑",
    value: "createdAt_asc",
    by: "createdAt",
    dir: "asc",
  },
  {
    label: "Дата добавления ↓",
    value: "createdAt_desc",
    by: "createdAt",
    dir: "desc",
  },
];

export const BrandsPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [brands, setBrands] = useState([]);
  const [brandsCount, setBrandsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [changeBrand, setChangeBrand] = useState({});
  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
        (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
      )
    : { value: "", label: "" };
  const [sort, setSort] = useState(sortByDefault);

  const getAndSetBrands = () => {
    getBrands({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      sort,
    })
      .then((res) => {
        setBrands(res.items);
        setBrandsCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (sort.by && sort?.dir) {
      url.append("sortBy", sort.by);
      url.append("sortDir", sort.dir);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search, sort]);

  useEffect(() => {
    setLoading(true);
    getAndSetBrands();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort]);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetBrands();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const searchHandler = () => {
    if (!search) return;
    setLoading(true);
    getAndSetBrands();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const isSearch = () => {
    if (search) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setSearch("");
  };

  const editHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateBrand({
      id: row.id,
      active: row.active === "Да" ? true : false,
      name: row.name,
    })
      .then(() => getAndSetBrands())
      .finally(() => {});
  };

  const setBrandHandler = () => {
    if (!changeBrand?.value || !selected.length) return;
    setLoading(true);
    setBrand({ ids: selected, brand: changeBrand.value })
      .then(() => {
        setSelected([]);
        getAndSetBrands();
      })
      .catch((e) => console.error(e));
  };

  const selectRow = {
    mode: "checkbox",
    bgColor: "#bfbfbf",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelected((prev) => {
        if (!isSelect) {
          prev.splice(
            prev.findIndex((val) => val === row.id),
            1
          );
          return [...prev];
        }
        return [...prev, row.id];
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setSelected([]);
      } else {
        setSelected(brands.map((val) => val.id));
      }
    },
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "sort":
        setSort(val);
        break;
      default:
        break;
    }
  };

  return (
    <Container fluid className="p-0 brands">
      {loading && <MySpinner overlay />}

      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Бренды</h1>
          <Form className="mb-3">
            <Row>
              <Col lg="4">
                <Label>Поиск</Label>
                <InputGroup>
                  <Input
                    name="search"
                    placeholder="По бренду"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button color="primary" onClick={searchHandler}>
                    Поиск
                  </Button>
                </InputGroup>
              </Col>

              <Col lg="3">
                <Label>Сортировка</Label>
                <InputGroup>
                  <Select
                    name="sort"
                    placeholder="Сортировка"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={SORT_FIELDS}
                    value={sort}
                    defaultValue={[{ value: "", label: "" }]}
                    onChange={selectHandler}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col lg="2">
              <Button onClick={() => setOpenModal(true)} className="mb-3" color="primary">
                Добавить бренд
              </Button>
            </Col>
            {selected.length > 0 && (
              <Col lg="4">
                <InputGroup>
                  <SelectSearch
                    name="changeBrand"
                    placeholder="Выберите бренд"
                    functionSuggestion={brandsSuggestion}
                    value={changeBrand}
                    setValue={setChangeBrand}
                  />
                  <Button onClick={setBrandHandler} color="primary">
                    Изменить бренд у номенклатуры
                  </Button>
                </InputGroup>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>

      <BootstrapTable
        keyField="id"
        data={brands}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            editHandler(oldData, newData, row),
        })}
        selectRow={selectRow}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={brandsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />

          <CreateBrandModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            action={getAndSetBrands}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
