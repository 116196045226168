import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Trash, Edit } from "react-feather";
import classNames from "classnames";

import {
  createContract,
  getDogovor,
  removeContract,
  setDefaultContract,
} from "services/api";
import { MySpinner } from "components/MySpinner";
import { dateFormat, dateFormatU } from "utils/format";
import { Checkbox } from "components/Checkbox";
import { TableButton } from "components/TableButton";

import "./contracts.scss";
import { FormTitle } from "components/FormTitle";
import { toast } from "react-toastify";

export const Contracts = ({ user }) => {
  const [loading, setLoading] = useState(false);

  const [contracts, setContracts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [date, setDate] = useState("");
  const [code1C, setCode1C] = useState("");
  const [contractId, setContractId] = useState(null);

  useEffect(() => {
    setContracts(user.contracts);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const addContractHandler = () => {
    setLoading(true);
    if (!name || !date) {
      toast.error("Заполните наименование и дату договора");
      return;
    }
    createContract({
      customer: user.id,
      name,
      number,
      date,
      code1C,
      contractId,
    })
      .then((res) => {
        setName("");
        setNumber("");
        setDate("");
        setCode1C("");
        setContracts(res);
        toast.success(
          contractId ? "Договор успешно обновлен" : "Договор успешно добавлен"
        );
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при добавлении договора"
        );
      })
      .finally(() => {
        setShowForm(false);
        setLoading(false);
      });
  };

  const resetForm = () => {
    setName("");
    setNumber("");
    setDate("");
    setCode1C("");
    setContractId(null);
    setShowForm(false);
  };

  const editContractShowForm = (id) => {
    setShowForm(true);
    const contract = contracts.find((contract) => contract.id === id);
    setContractId(contract.id);
    setName(contract.name);
    setNumber(contract.number);
    setDate(dateFormatU(contract.date));
    setCode1C(contract.code1C);
  };

  const removeContractHandler = (id) => {
    setLoading(true);
    removeContract({ id })
      .then(setContracts)
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении данных"
        );
      })
      .finally(() => setLoading(false));
  };

  const defaultContractHandler = (val, id) => {
    if (!val) {
      return;
    }
    setLoading(true);
    setDefaultContract({ id })
      .then((res) => {
        setContracts(res);
        toast.success("Данные успешно обновлены");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const dogovorHandler = (id) => {
    getDogovor({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Договор поставки ООО ЛИДЕР ТРАК.docx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderContractTable = () => (
    <Table>
      <thead>
        <tr>
          <th>Наименование</th>
          <th className="right">Номер</th>
          <th>Дата</th>
          <th>Код 1С</th>
          <th>Основной</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {contracts.map((item) => (
          <tr key={item.id} className={classNames({ inactive: !item.active })}>
            <td>{item.name}</td>
            <td className="right">{item.number}</td>
            <td>{dateFormat(item.date)}</td>
            <td>{item.code1C}</td>
            <td className="center">
              {item.active && (
                <Checkbox
                  value={item.id}
                  checked={item.main || false}
                  onChange={(val) => defaultContractHandler(val, item.id)}
                  disabled={!item.active}
                />
              )}
            </td>
            <td className="table-action">
              {item.active && (
                <div className="table-actions-block">
                  <TableButton
                    Icon={Trash}
                    onClick={() => removeContractHandler(item.id)}
                  />

                  <TableButton
                    Icon={Edit}
                    onClick={() => editContractShowForm(item.id)}
                  />

                  <Button
                    onClick={() => dogovorHandler(item.id)}
                    color="primary"
                    className="button-wide download"
                  >
                    Скачать
                  </Button>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <div className="contracts">
      <Card className="mb-0">
        <CardBody className="pb-2">
          <FormTitle>Договоры</FormTitle>
        </CardBody>
      </Card>

      {loading && <MySpinner overlay />}

      <div className="contracts__table">
        <div className="contracts__header"></div>
        {renderContractTable()}
        <div className="contracts__add">
          <Card className="contracts__add-form mb-0">
            <CardBody>
              {showForm ? (
                <>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">
                        Наименование договора
                      </Label>
                      <Input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      <span className="sub">
                        Не пишите в названии номер и дату!
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Номер договора</Label>

                      <Input
                        type="text"
                        className="mb-1"
                        onChange={(e) => setNumber(e.target.value)}
                        value={number}
                      />
                    </Col>

                    <Col className="elem-field">
                      <Label className="text-sm-right">Дата договора</Label>
                      <Input
                        type="date"
                        className="mb-1"
                        onChange={(e) => setDate(e.target.value)}
                        value={date}
                      />
                    </Col>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Код 1С</Label>
                      <Input
                        type="text"
                        className="mb-1"
                        onChange={(e) => setCode1C(e.target.value)}
                        value={code1C}
                      />
                    </Col>
                  </Row>

                  <div className="buttons">
                    <Button
                      color="primary"
                      onClick={addContractHandler}
                      className="button-wide contracts__save"
                    >
                      Сохранить договор
                    </Button>

                    <Button onClick={resetForm} className="button-wide">
                      Отменить
                    </Button>
                  </div>
                </>
              ) : (
                <Button onClick={() => setShowForm(true)} color="primary">
                  Добавить договор
                </Button>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
