import { axiosInstance } from "./api";

export const getMoving = async ({
  paginationCount,
  paginationOffset,
  search,
  number,
  admin,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
    search,
    number,
    admin,
  };

  const res = await axiosInstance.get("/moving.get", { params });
  return res.data;
};

export const getMovingOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/moving.getOne", { params });
  return res.data;
};

export const createMoving = async (props) => {
  const res = await axiosInstance.post("/moving.create", props);
  return res.data;
};

export const updateMoving = async (props) => {
  const res = await axiosInstance.post("/moving.update", props);
  return res.data;
};

export const carryOutMoving = async (props) => {
  const res = await axiosInstance.post("/moving.carryOut", props);
  return res.data;
};

export const updateMovingProduct = async (props) => {
  const res = await axiosInstance.post("/moving.updateProduct", props);
  return res.data;
};

export const removeMovingProduct = async (props) => {
  const res = await axiosInstance.post("/moving.removeProduct", props);
  return res.data;
};

export const getStock = async (params) => {
  const res = await axiosInstance.get("/stock.get", { params });
  return res.data;
};

export const updatePriceByStock = async (props) => {
  const res = await axiosInstance.post("/stock.updatePriceByStock", props);
  return res.data;
};

export const getWarehouses = async () => {
  const res = await axiosInstance.get("/warehouses.get");
  return res.data;
};

export const getWriteOffs = async ({
  paginationCount,
  paginationOffset,
  search,
  number,
  admin,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
    search,
    number,
    admin,
  };

  const res = await axiosInstance.get("/writeOffs.get", { params });
  return res.data;
};

export const getWriteOffOne = async (id) => {
  const params = {
    id,
  };

  const res = await axiosInstance.get("/writeOffs.getOne", { params });
  return res.data;
};

export const createWriteOff = async (props) => {
  const res = await axiosInstance.post("/writeOffs.create", props);
  return res.data;
};

export const carryOutWriteOff = async (props) => {
  const res = await axiosInstance.post("/writeOffs.carryOut", props);
  return res.data;
};

export const getMisgradings = async ({
  paginationCount,
  paginationOffset,
  search,
  number,
  admin,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
    search,
    number,
    admin,
  };

  const res = await axiosInstance.get("/misgradings.get", { params });
  return res.data;
};

export const getMisgradingOne = async (id) => {
  const params = {
    id,
  };

  const res = await axiosInstance.get("/misgradings.getOne", { params });
  return res.data;
};

export const createMisgrading = async (props) => {
  const res = await axiosInstance.post("/misgradings.create", props);
  return res.data;
};

export const carryOutMisgrading = async (props) => {
  const res = await axiosInstance.post("/misgradings.carryOut", props);
  return res.data;
};

export const updateMisgradingProduct = async (props) => {
  const res = await axiosInstance.post("/misgradings.updateProduct", props);
  return res.data;
};

export const removeMisgradingProduct = async (props) => {
  const res = await axiosInstance.post("/misgradings.removeProduct", props);
  return res.data;
};


