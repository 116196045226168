import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Select from "react-select";

import useQuery from "utils/useQuery";
import { Pagination } from "components/Pagination";
import {
  downloadAnaliticsSale,
  generateAnaliticsSale,
  getAnaliticsSale,
  getProgress,
  getProgressAnaliticsSale,
} from "services/apiAnalitics";
import { AnaliticsSaleTable } from "features/AnaliticsSale/AnaliticsSaleTable";
import { SelectSearch } from "components/SelectSearch";
import { brandsSuggestion } from "services/api";

import "./analiticsSale.scss";
import { dateFormatU, priceFormat } from "utils/format";
import { SelectProvider } from "components/SelectProvider";

const SORT_FIELDS = [
  { label: "Фикс прайс ↑", value: "fixPrice_asc", by: "fixPrice", dir: "asc" },
  {
    label: "Фикс прайс ↓",
    value: "fixPrice_desc",
    by: "fixPrice",
    dir: "desc",
  },
];

registerLocale("ru", ru);

export const AnaliticsSalePage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [analiticsSale, setAnaliticsSale] = useState([]);
  const [analiticsSaleCount, setAnaliticsSaleCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({});
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );
  const [intervalBalance, setIntervalBalance] = useState(null);
  const [brand, setBrand] = useState(
    query.get("brand")
      ? { label: query.get("brandName"), value: query.get("brand") }
      : null
  );
  const [provider, setProvider] = useState(
    query.get("provider")
      ? { label: query.get("providerName"), value: query.get("provider") }
      : null
  );

  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
        (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
      )
    : null;

  const [sort, setSort] = useState(sortByDefault);

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(10);

  const [totalCount, setTotalCount] = useState(0);
  const [totalSumm, setTotalSumm] = useState(0);
  const [totalCostPrice, setTotalCostPrice] = useState(0);
  const [totalAddExpenses, setTotalAddExpenses] = useState(0);
  const [totalMargin, setTotalMargin] = useState(0);

  const getAndSetAnaliticsSale = () => {
    getAnaliticsSale({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      brand: brand?.value,
      sort,
      provider: provider?.value,
    })
      .then((res) => {
        setAnaliticsSale(res.items);
        setAnaliticsSaleCount(res.totalItems);
        setTotalCount(res.totalCount);
        setTotalSumm(res.totalSumm);
        setTotalCostPrice(res.totalCostPrice);
        setTotalAddExpenses(res.totalAddExpenses);
        setTotalMargin(res.totalMargin);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetAnaliticsSale();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (sort?.by && sort?.dir) {
      url.append("sortBy", sort.by);
      url.append("sortDir", sort.dir);
    }

    if (brand?.value) {
      url.append("brand", brand?.value);
      url.append("brandName", brand?.label);
    }

    if (provider?.value) {
      url.append("provider", provider?.value);
      url.append("providerName", provider?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    dateStart,
    dateEnd,
    brand,
    sort,
    provider,
  ]);

  const isSearch = () => {
    if (
      search ||
      dateStart ||
      dateEnd ||
      brand?.value ||
      sort ||
      provider?.value
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    setLoading(true);
    getAndSetAnaliticsSale();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetAnaliticsSale();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dateStart, dateEnd, brand?.value, sort, provider?.value]);

  const getAndSetProgress = () => {
    getProgressAnaliticsSale().then((res) => {
      setProgress(res);

      if (typeof res?.progress !== "undefined") {
        setLoadingGenerate(true);
      } else {
        if (loadingGenerate) {
          toast.success("Генерация отчета завершена");
          setLoadingGenerate(false);
        }
      }
    });
  };
  
  useEffect(() => {
    getAndSetProgress();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilter = () => {
    setSearch("");
    setDateStart("");
    setDateEnd("");
    setBrand(null);
    setProvider(null);
  };

  const generateAnaliticsSaleHandler = () => {
    setLoadingGenerate(true);
    toast.success("Генерация отчета запущена");
    generateAnaliticsSale()
      .then((res) => {})
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  useEffect(() => {
    if (loadingGenerate) {
      setIntervalBalance(
        setInterval(() => {
          getAndSetProgress();
        }, 3000)
      );
    } else {
      clearInterval(intervalBalance);
      setIntervalBalance(null);
      setLoadingGenerate(false);
    }

    return () => {
      clearInterval(intervalBalance);
    };
  }, [loadingGenerate]);

  const downloadHandler = () => {
    setLoading(true);
    // downloadAnaliticsSale()
    //   .then((res) => {
    //     const url = window.URL.createObjectURL(new Blob([res]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", `Состояние склада.xlsx`);
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const onSort = (field, order) => {
    setSort({
      by: field,
      dir: order,
    });
  };

  return (
    <Container fluid className="p-0 analitics-sale">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Отчет по продажам</h1>

          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={3}>
                <Label>Дата</Label>

                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>

              <Col md={3}>
                <Label>Бренд</Label>
                <SelectSearch
                  name="brand"
                  placeholder="Выберите бренд"
                  functionSuggestion={brandsSuggestion}
                  value={brand}
                  setValue={setBrand}
                />
              </Col>
              <Col md={3}>
                <Label>Поставщик</Label>
                <SelectProvider provider={provider} setProvider={setProvider} />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col md="3" className="d-flex flex-column align-items-start">
              <Button
                color="primary"
                className="button-wide btn btn-primary"
                onClick={generateAnaliticsSaleHandler}
                disabled={loadingGenerate}
              >
                Сгенерировать
                {loadingGenerate && (
                  <div className="spinner-wrapper">
                    <Spinner color="danger" size="sm" />
                  </div>
                )}
              </Button>
              {loadingGenerate && (
                <>Процесс генерации: {progress?.progress} %</>
              )}
            </Col>
            <Col md="3">
              <Button
                color="primary"
                className="button-wide btn btn-primary"
                onClick={downloadHandler}
              >
                Скачать
              </Button>
            </Col>
          </Row>

          <Row>
            <Col md="2">
              <Label className="text-sm-right">Кол-во общее</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalCount)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Объем продаж</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalSumm)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Себестоимость</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalCostPrice)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Доп расходы</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalAddExpenses)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Маржинальность</Label>
            </Col>
            <Col md="4">
              <b>{totalMargin} %</b>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <AnaliticsSaleTable analiticsSale={analiticsSale} onSort={onSort} />

        <Card>
          <CardBody>
            <Pagination
              totalCount={analiticsSaleCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
