import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import {
  getNomenclature,
  priceMailing,
  updateNomenclature,
} from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { NomenclatureModal } from "features/Nomenclature/NomenclatureModal";
import { NomenclatureTable } from "features/Nomenclature/NomenclatureTable";
import { Checkbox } from "components/Checkbox";

import "./nomenclature.scss";

const SORT_FIELDS = [
  {
    label: "Дата добавления ↑",
    value: "createdAt_asc",
    by: "createdAt",
    dir: "asc",
  },
  {
    label: "Дата добавления ↓",
    value: "createdAt_desc",
    by: "createdAt",
    dir: "desc",
  },
  {
    label: "Активность ↓",
    value: "active_asc",
    by: "active",
    dir: "asc",
  },
  {
    label: "Активность ↑",
    value: "active_desc",
    by: "active",
    dir: "desc",
  },
];

export const NomenclaturePage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [nomenclature, setNomenclature] = useState([]);
  const [nomenclatureCount, setNomenclatureCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [withPrice, setWithPrice] = useState(query.get("withPrice") || false);
  const [isShowReset, setIsShowReset] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(null);
  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
        (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
      )
    : { value: "", label: "" };
  const [sort, setSort] = useState(sortByDefault);

  const getAndSetNomenclature = () => {
    getNomenclature({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      sort,
      withPrice,
    })
      .then((res) => {
        setNomenclature(res.items);
        setNomenclatureCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (withPrice) {
      url.append("withPrice", withPrice);
    }

    if (sort.by && sort?.dir) {
      url.append("sortBy", sort?.by);
      url.append("sortDir", sort?.dir);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search, sort, withPrice]);

  useEffect(() => {
    setLoading(true);
    getAndSetNomenclature();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort]);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetNomenclature();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setLoading(true);
    getAndSetNomenclature();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withPrice]);

  const searchHandler = () => {
    if (!search) return;
    setLoading(true);
    getAndSetNomenclature();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const sendPriceList = () => {
    setLoading(true);
    priceMailing()
      .then((res) => {
        toast.success("Прайс успешно отправлен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const isSearch = () => {
    if (search) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setSearch("");
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "sort":
        setSort(val);
        break;
      default:
        break;
    }
  };

  return (
    <Container fluid className="p-0 nomenclature">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Номенклатура</h1>
          <Form className="mb-3">
            <Row>
              <Col md="4">
                <Label>Поиск</Label>
                <InputGroup>
                  <Input
                    name="search"
                    placeholder="По артикулу"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button color="primary" onClick={searchHandler}>
                    Поиск
                  </Button>
                </InputGroup>
              </Col>

              <Col md="3">
                <Label>Сортировка</Label>
                <InputGroup>
                  <Select
                    name="sort"
                    placeholder="Сортировка"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={SORT_FIELDS}
                    value={sort}
                    onChange={selectHandler}
                  />
                </InputGroup>
              </Col>
              <Col md="3">
                <Label>С себестоимостью</Label>
                <InputGroup>
                  <Checkbox
                    name="withPrice"
                    checked={withPrice}
                    onChange={(e) => setWithPrice(e.target.checked)}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col md="2">
              <Button onClick={() => setOpenModal(true)} color="primary">
                Добавить номенклатуру
              </Button>
            </Col>
            <Col md="2">
              <Button onClick={sendPriceList} color="primary">
                Отправить прайс-лист
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <NomenclatureTable
        nomenclature={nomenclature}
        getAndSetNomenclature={getAndSetNomenclature}
        setLoading={setLoading}
        setOpenModal={setOpenModal}
        setId={setId}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={nomenclatureCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>

      <NomenclatureModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={id}
        getAndSetNomenclature={getAndSetNomenclature}
      />
    </Container>
  );
};
