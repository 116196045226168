import React from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { priceFormat } from "utils/format";

import "./acceptanceActProductTable.scss";
import { TableButton } from "components/TableButton";
import { Trash2 } from "react-feather";
import { removeAcceptanceActProduct } from "services/apiSupply";
import { toast } from "react-toastify";

export const AcceptanceActProductTable = ({
  products,
  setProducts,
  setLoading,
  carriedOut,
}) => {
  const removeProductsHandler = (id) => {
    setLoading(true);
    removeAcceptanceActProduct({ id })
      .then((res) => {
        setProducts(res.products);
        toast.success("Товар успешно удален");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <span className="cute">{row.nomenclature?.name}</span>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => priceFormat(parseFloat(cell)),
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "editable right",
      footer: () => null,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "editable right",
      editable: false,
      footer: (columnData) => {
        const total = columnData.reduce((acc, item) => acc + parseInt(item), 0);
        return total;
      },
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => priceFormat(parseFloat(cell)),
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "editable right",
      footer: (columnData) => {
        const total = columnData.reduce(
          (acc, item) => acc + parseFloat(item),
          0
        );
        return priceFormat(total);
      },
    },
    {
      dataField: "countryCode",
      text: "Код страны",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      footer: () => null,
    },
    {
      dataField: "countryName",
      text: "Страна",
      formatter: (cell) => <span className="cute">{cell}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "gtd",
      text: "ГТД",
      formatter: (cell) => <span className="cute">{cell}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      footer: () => null,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <TableButton
          Icon={Trash2}
          onClick={() => removeProductsHandler(row.id)}
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      footer: () => null,
      editable: false,
      hidden: carriedOut,
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="acceptance-act-product"
        keyField="id"
        data={products}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
      />
    </>
  );
};
