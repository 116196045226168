export const MARKUPS = [
  { label: '3%', value: 3 },
  { label: '5%', value: 5 },
  { label: '7%', value: 7 },
  { label: '9%', value: 9 },
  { label: '11%', value: 11 },
  { label: '13%', value: 13 },
  { label: '15%', value: 15 },
  { label: '17%', value: 17 },
  { label: '20%', value: 20 },
  { label: '25%', value: 25 }
];