import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat } from "utils/format";
import { useHistory } from "react-router-dom";

import { CheckCircle } from "react-feather";

import user from "assets/img/user.svg";
import "./ordersTable.scss";

export const EnteringBalancesTable = ({ enteringBalances }) => {
  const history = useHistory();
  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      headerStyle: () => {
        return { width: "3%" };
      },
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "date",
      text: "Дата",
      formatter: (cell, row) => <>{dateFormat(cell)}</>,
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "admin",
      text: "Кто создал",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "9%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/stock/enteringBalance/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={enteringBalances}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
