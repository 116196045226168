import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import InputMask from "react-input-mask";
import { Link, useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CheckCircle } from "react-feather";
import { MySpinner } from "components/MySpinner";

import { dateFormat } from "utils/format";
import { HeaderPage } from "components/HeaderPage";

import {
  carryOutReturnCustomer,
  createReturnCustomer,
  getOrderByNumber,
  getReturnCustomerOne,
} from "services/api";
import { ReturnCustomerProductTableNew } from "features/ReturnsCustomer/ReturnCustomerProductTableNew";
import { ReturnCustomerProductTable } from "features/ReturnsCustomer/ReturnCustomerProductTable";

import "./returnCustomer.scss";

export const ReturnCustomerOnePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(!id ? true : false);
  const [number, setNumber] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [order, setOrder] = useState({});
  const [date, setDate] = useState(new Date());
  const [carriedOut, setCarriedOut] = useState(false);
  const [comment, setComment] = useState("");
  const [products, setProducts] = useState([]);

  const getAndSetReturnCustomer = () => {
    getReturnCustomerOne(id)
      .then((res) => {
        setComment(res.comment);
        setNumber(res.number);
        setProducts(res.products);
        setDate(dateFormat(res.date));
        setOrder(res.order);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAndSetReturnCustomer();
  }, []);

  const getAndSetOrder = () => {
    if (!orderNumber) return;
    getOrderByNumber(orderNumber)
      .then((res) => {
        setOrder(res);
        setProducts(
          res.products.map((val) => {
            return {
              ...val,
              countOld: val.count,
              count: 0,
            };
          })
        );
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const createReturnCustomerHandler = () => {
    setLoading(true);
    createReturnCustomer({
      date,
      order: {
        id: order.id,
      },
      comment,
      products: products.filter((prod) => prod.count > 0),
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Возврат от клиента успешно создан");
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    setLoading(true);
    carryOutReturnCustomer({ id })
      .then((res) => {
        setCarriedOut(res.carriedOut);
        toast.success("Возврат от клиента успешно проведен");
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новый возврат от клиента`;
    }

    return `Возврат от клиента № ${number || ""}`;
  };

  return (
    <Container fluid className="p-0 return-to-provider-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      {loading && <MySpinner overlay />}

      <HeaderPage title={renderTitle()}>
        <>
          {!isNew && (
            <>
              <div className="return-to-provider-one__date-block">
                <Label>Дата</Label>
                <InputMask
                  id="date"
                  name="date"
                  type="text"
                  onChange={() => {}}
                  value={date}
                  mask={"99.99.9999"}
                  className="form-control date"
                  disabled
                />
              </div>

              <div className="corr-order-one__carried-out-button">
                {carriedOut && <CheckCircle color="green" />}

                <Button
                  color="primary"
                  onClick={carryOutHandler}
                  className="button-wide ml-1"
                >
                  {carriedOut ? `Распровести` : `Провести`}
                </Button>
              </div>
            </>
          )}
        </>
      </HeaderPage>

      <Card className="entering-balance-one__form mb-0">
        <CardBody className="pb-3">
          {isNew ? (
            <>
              <Row>
                <Col md="3">
                  <Label className="text-sm-right">На основании заказа</Label>
                </Col>
                <Col md="4">
                  <InputGroup>
                    <Input
                      name="orderNumber"
                      placeholder="Заказ"
                      value={orderNumber || ""}
                      onChange={(e) => setOrderNumber(e.target.value)}
                    />
                    <Button color="primary" onClick={getAndSetOrder}>
                      загрузить
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Label className="text-sm-right">Заказ</Label>
                </Col>
                <Col md="9">
                  <Link to={`/sales/orders/${order.id}`} target="_blank">
                    <b>{order.number}</b>
                  </Link>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col md="3">
                <Label className="text-sm-right">На основании заказа</Label>
              </Col>
              <Col md="9">
                <Link to={`/sales/orders/${order.id}`} target="_blank">
                  <b>{order.number}</b>
                </Link>
              </Col>
            </Row>
          )}

          <Row>
            <Col md="3">
              <Label className="text-sm-right">Клиент</Label>
            </Col>
            <Col md="9">
              <b>{order.customer?.name}</b>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Создал заказ</Label>
            </Col>
            <Col md="9">
              <b>{order.admin?.name}</b>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label className="text-sm-right">Комментарий</Label>
              <Input
                type="textarea"
                name="comment"
                placeholder="Комментарий"
                value={comment || ""}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {isNew ? (
        <ReturnCustomerProductTableNew products={products} />
      ) : (
        <ReturnCustomerProductTable products={products} />
      )}
      <Card>
        <CardBody>
          {isNew && (
            <Button
              color="primary"
              onClick={createReturnCustomerHandler}
              className="button-wide"
            >
              Создать
            </Button>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
