import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import user from "assets/img/user.svg";

import "./movingTable.scss";
import { CheckCircle } from "react-feather";
import { dateFormat } from "utils/format";

export const MovingTable = ({ moving, rowEvents }) => {
  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      headerStyle: () => {
        return { width: "3%" };
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "date",
      text: "Дата",
      formatter: (cell) => dateFormat(cell),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "admin",
      text: "Создал",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "4%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={moving}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        classes="pointer"
        rowEvents={rowEvents}
      />
    </>
  );
};
