import React from "react";
import classNames from "classnames";
import BootstrapTable from "react-bootstrap-table-next";

import { dateFormat, dateTimeFormat, priceFormat } from "utils/format";
import user from "assets/img/user.svg";

import "./analiticsReceivables.scss";

export const AnaliticsReceivablesTable = ({ analiticsReceivables, onSort }) => {
  const tableColumns = [
    {
      dataField: "customer",
      text: "Контрагент",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
    {
      dataField: "admin",
      text: "Менеджер",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "20%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "remainingDebt",
      text: "Остаток долга на сегодня",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell) =>
        classNames("right bold", { red: cell < 0, green: cell > 0 }),
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "remainingDebtExpired",
      text: "Сумма просрочки",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => priceFormat(parseInt(cell)),
      headerClasses: () => "right",
      classes: (cell) =>
        classNames("right", {
          red: cell > 0,
        }),
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "daysDebtCount",
      text: "Дней долга",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "delayDayCount",
      text: "Отсрочка по договору",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "remainingDaysExpired",
      text: "Просрочено дней",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell) =>
        classNames("right", {
          red: cell > 0,
        }),
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
  ];

  const expandRow = {
    renderer: (row) => {
      return (
        <div className="react-bootstrap-table">
          <table className="table table-hover">
            <tbody>
              {row.orders?.map((val) => (
                <tr key={val.id}>
                  <td style={{ width: "12%" }}></td>
                  <td style={{ width: "5%" }}>№ {val.number}</td>
                  <td style={{ width: "6%" }}>
                    {dateTimeFormat(val.shippingDate)}
                  </td>
                  <td style={{ width: "9%" }}>{val.contract}</td>
                  <td style={{ width: "4%" }} className="right">
                    {priceFormat(val.summ)}
                  </td>
                  <td
                    style={{ width: "8%" }}
                    className={classNames("right", {
                      red: val.remainingDebt < 0,
                      green: val.remainingDebt > 0,
                    })}
                  >
                    {priceFormat(parseInt(val.remainingDebt))}
                  </td>
                  <td
                    style={{ width: "8%" }}
                    className={classNames("right", {
                      red: val.remainingDaysExpired > 0,
                    })}
                  >
                    {priceFormat(parseInt(val.remainingDebtExpired))}
                  </td>
                  <td style={{ width: "8%" }} className="right">
                    {val.daysDebtCount}
                  </td>
                  <td style={{ width: "8%" }} className="right">
                    {priceFormat(val.delayDayCount)}
                  </td>
                  <td
                    style={{ width: "8%" }}
                    className={classNames("right", {
                      red: val.remainingDaysExpired > 0,
                    })}
                  >
                    {val.remainingDaysExpired}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={analiticsReceivables}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        expandRow={expandRow}
      />
    </>
  );
};
