import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

import { getAdmins } from "services/api";
import { getOrdersToProvider } from "services/apiSupply";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { OrdersToProviderTable } from "features/OrdersToProvider/OrdersToProviderTable";
import { dateFormatU, dateTimeFormat } from "utils/format";
import { SelectProvider } from "components/SelectProvider";
import { priceFormat } from "utils/format";
import { Checkbox } from "components/Checkbox";

import "./ordersToProvider.scss";

registerLocale("ru", ru);

export const OrdersToProviderPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [ordersToProvider, setOrdersToProvider] = useState([]);
  const [ordersToProviderCount, setOrdersToProviderCount] = useState(0);
  const [ordersToProviderSumm, setOrdersToProviderSumm] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(10);
  const [provider, setProvider] = useState(
    query.get("provider")
      ? { label: query.get("providerName"), value: query.get("provider") }
      : null
  );
  const [search, setSearch] = useState(query.get("search") || "");
  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );

  const [number, setNumber] = useState(query.get("number") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [admins, setAdmins] = useState([]);

  const [admin, setAdmin] = useState(
    localStorage.getItem("filterAdmin")
      ? JSON.parse(localStorage.getItem("filterAdmin"))
      : null
  );

  const getAndSetAdmins = () => {
    getAdmins()
      .then((res) => {
        const adminsConvert = res.map((item) => {
          return {
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        setAdmins(adminsConvert);
      })
      .catch((e) => console.error(e));
  };

  const getAndSetOrdersToProvider = () => {
    getOrdersToProvider({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      number,
      provider: provider?.value,
      admin: admin?.value
    })
      .then((res) => {
        setOrdersToProvider(res.items);
        setOrdersToProviderCount(res.totalItems);
        setOrdersToProviderSumm(res.totalSumm);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetOrdersToProvider();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (provider?.value) {
      url.append("provider", provider.value);
      url.append("providerName", provider.label);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (number) {
      url.append("number", number);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    provider,
    dateStart,
    dateEnd,
    number
  ]);

  useEffect(() => {
    setLoading(true);
    getAndSetAdmins();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        localStorage.setItem("filterAdmin", JSON.stringify(val));
        setAdmin(val);
        break;
      default:
        break;
    }
  };

  const isSearch = () => {
    if (
      search ||
      dateStart ||
      dateEnd ||
      number ||
      provider?.value ||
      admin?.value
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    setLoading(true);
    getAndSetOrdersToProvider();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetOrdersToProvider();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    dateStart,
    dateEnd,
    number,
    provider,
    admin
  ]);

  const resetFilter = () => {
    setProvider(null);
    setSearch("");
    setDateStart("");
    setDateEnd("");
    setNumber("");
    setAdmin(null);
    localStorage.removeItem("filterAdmin");
  };

  const numberHandler = (e) => {
    setNumber(e.target.value.replace(/[^+\d]/g, ""));
  };

  return (
    <Container fluid className="p-0 orders-to-provider">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Заказы поставщику</h1>

          <Form className="mb-3">
            <Row>
              <Col>
                <Label>Дата</Label>
                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>
              <Col>
                <Label>Заказ поставщику №</Label>
                <Input
                  name="number"
                  placeholder="По номеру"
                  value={number}
                  onChange={numberHandler}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col>
                <Label>Поставщик</Label>
                <SelectProvider provider={provider} setProvider={setProvider} />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Создал</Label>
                <Select
                  name="admin"
                  placeholder="Создал поступление"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                Заказов: {ordersToProviderCount} на сумму{" "}
                {priceFormat(parseFloat(ordersToProviderSumm))}
              </Col>
            </Row>
          </Form>

          <Row>
            <Col md="2">
              <Link
                to={`/receipt/ordersToProvider/new`}
                color="primary"
                className="button-wide btn btn-primary"
              >
                Добавить заказ пост-ку
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <OrdersToProviderTable ordersToProvider={ordersToProvider} />

        <Card>
          <CardBody>
            <Pagination
              totalCount={ordersToProviderCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
