import React, { useState } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { toast } from "react-toastify";

import { updatePassword } from "services/api";
import { MySpinner } from "components/MySpinner";

import "react-toastify/dist/ReactToastify.css";
import "./password.scss";
import { FormTitle } from "components/FormTitle";

export const Password = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const passwordHandler = () => {
    if (!password || password.length < 8) {
      toast.error(
        "Заполните пароль. Длина пароля должне быть не менее 8 символов."
      );
      return;
    }

    setLoading(true);
    updatePassword({
      id: user.id,
      password,
    })
      .then((res) => {
        setPassword("");
        toast.success("Пароль успешно изменен");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении пароля"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generatePasswordHandler = () => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    const generatedValue = [];

    for (let i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      generatedValue.push(chars.substring(randomNumber, randomNumber + 1));
    }

    setPassword(generatedValue.join(""));
  };

  return (
    <div className="password">
      <Card className="mb-0">
        <CardBody className="pb-2">
          <FormTitle>Изменить пароль клиента</FormTitle>
          <div className="password__table">
            {loading && <MySpinner overlay />}

            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">Пароль</Label>
                <Input
                  type="text"
                  className="mb-1"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </Col>
              <Button
                color="primary"
                className="with-label"
                onClick={generatePasswordHandler}
              >
                Сгенерить
              </Button>
            </Row>

            <Button
              color="primary"
              onClick={passwordHandler}
              className="password__save"
            >
              Изменить пароль
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
