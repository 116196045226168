import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Label } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import { useCart } from "../CartContext";
import { tableColumns } from "./tableData";
import { MySpinner } from "components/MySpinner";
import { cartClean, cartUpdate } from "services/api";

import "./cart.scss";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";

export const CartTable = ({
  orderSuccess,
  customer,
  setCustomer,
  getAndSetPrice,
}) => {
  const { cart, setCart, cartLoading } = useCart();
  const [loading, setLoading] = useState(false);

  const beforeSaveCell = (oldValue, newValue, row, column, done) => {
    if (!customer.value) {
      done(false);
      alert("Для изменения цены в корзине выберите клиента");
      return { async: true };
    }
  };

  const priceHandler = (row, newPrice) => {
    setLoading(true);
    cartUpdate({ id: row.id, price: parseInt(newPrice) })
      .then((res) => setCart(res.cart))
      .finally(() => setLoading(false));
  };

  const cartCleanHandler = () => {
    setLoading(true);
    cartClean()
      .then((res) => setCart(res.cart))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setCart([]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSuccess]);

  return (
    <div className="cart">
      {(cartLoading || loading) && <MySpinner overlay />}

      <Card className="mb-0">
        <CardBody>
          <h3>
            Корзина
            {cart.length > 0 && (
              <Button onClick={cartCleanHandler} className="ml-2">
                Очистить
              </Button>
            )}
          </h3>

          <div className="cart__right-block">
            <div className="cart__inline-block">
              <Label>Клиент</Label>
              <SelectCustomer
                customer={customer}
                setCustomer={setCustomer}
                getAndSetPrice={getAndSetPrice}
              />
            </div>
          </div>
        </CardBody>
      </Card>

      <BootstrapTable
        keyField="id"
        data={cart}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          beforeSaveCell,
          afterSaveCell: (oldData, newData, row) =>
            parseInt(oldData) !== parseInt(newData) &&
            priceHandler(row, newData),
        })}
      />
    </div>
  );
};
