import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Row, Label, Col, CardBody, Card } from "reactstrap";
import { toast } from "react-toastify";
import { CheckCircle } from "react-feather";

import { HeaderPage } from "components/HeaderPage";
import { baseUrl, endPicking, getOrder } from "services/api";

import { MySpinner } from "components/MySpinner";
import { useUser } from "features/Users/UserContext";
import { OrderPickingProductTable } from "features/OrderPicking/OrderPickingProductTable";
import { Helmet } from "react-helmet";

import "./orderPickingOne.scss";
import { dateFormat, dateTimeFormat } from "utils/format";

export const OrdersPickingOnePage = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  const getAndSetOrder = () => {
    getOrder(id)
      .then((res) => {
        setOrder(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetOrder();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const endPickingHandler = () => {
    if (!window.confirm("Вы действительно хотите завершить сборку?")) {
      return;
    }

    endPicking({ id })
      .then((res) => {
        setOrder(res);
        toast.success("Заказ успешно собран");
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  if (order.status?.sort !== 4 && order.status?.sort !== 5) {
    return <>Заявки на сборку не существует</>;
  }

  return (
    <Container fluid className="p-0 order-picking-one">
      <Helmet>
        <title>{`Заявка на сборку заказа № ${order.number || ""}`}</title>
      </Helmet>
      <HeaderPage title={`Заявка на сборку заказа № ${order.number || ""}`} />

      {loading && <MySpinner overlay />}
      <Card className="order-picking-one__form mb-0">
        <CardBody className="pb-3">
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Заказ</Label>
            </Col>
            <Col md="9">
              <b>
                {order.number} от {dateTimeFormat(order.date)}
              </b>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Клиент</Label>
            </Col>
            <Col md="9">
              <b>{order.customer?.name}</b>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Адрес</Label>
            </Col>
            <Col md="9">
              <b>{order.deliverySettings}</b>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Менеджер</Label>
            </Col>
            <Col md="9">
              <b>{order.admin?.name}</b>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Комментарий к заказу</Label>
            </Col>
            <Col md="9">
              <b>{order.comment}</b>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Сборщик</Label>
            </Col>
            <Col md="9">
              <b>
                {user.firstName} {user.lastName}
              </b>
            </Col>
          </Row>

          <div className="button-block">
            <Button
              href={`${baseUrl}/orderPicking.print?id=${id}&admin=${user.id}`}
              target="_blank"
              color="primary button-wide"
            >
              Распечатать
            </Button>

            {order.status?.sort === 4 && (
              <Button
                onClick={endPickingHandler}
                color="primary button-wide ml-3"
              >
                Завершить сборку
              </Button>
            )}
            {order.status?.sort >= 5 && (
              <div className="end-picking">
                <CheckCircle color="green" />
                Сборка завершена
              </div>
            )}
          </div>
        </CardBody>
      </Card>

      <OrderPickingProductTable products={order.products} />
    </Container>
  );
};
