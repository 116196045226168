import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { useCart } from "features/Cart/CartContext";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  Input,
  Alert,
} from "reactstrap";
import { createOrder, getAddresses, getContracts } from "services/api";
import { SelectCustomer } from "../SelectCustomer";

import "./orderForm.scss";

export const DELIVERY = [
  { value: 1, label: "Самовывоз" },
  { value: 2, label: "Доставка курьером" },
  { value: 3, label: "Доставка СДЭК" },
];

export const SKLAD = [
  { value: "000000012", label: "Европарт СВХ" },
  { value: "000000016", label: "Тракмоторс СВХ" },
  { value: "", label: "ЛИДЕР ТРАК" },
];

export const OrderForm = ({
  customer,
  setCustomer,
  getAndSetPrice,
  setOrderSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [delivery, setDelivery] = useState({});
  const [sklad, setSklad] = useState({ value: "", label: "ЛИДЕР ТРАК" });
  const [comment, setComment] = useState("");
  const [address, setAddress] = useState({ value: "", label: "" });
  const [contracts, setContracts] = useState([]);
  const [contract, setContract] = useState({ value: "", label: "Не выбран" });
  const [addressText, setAddressText] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [order, setOrder] = useState({});
  const [existOrder, setExistOrder] = useState("");
  const [error, setError] = useState("");

  const { cart } = useCart();

  const orderValid = () => {
    if (delivery?.value === 2) {
      if (customer?.value && delivery?.value && (address?.value || addressText))
        return true;
    } else {
      if (customer?.value && delivery?.value) return true;
    }
    return false;
  };

  const addressesMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.address, main: item.main };
    });

  const contractsMap = (res) =>
    res
      .filter((item) => item.active === true)
      .map((item) => {
        return { value: item.id, label: item.label, main: item.main };
      });

  useEffect(() => {
    setAddresses([]);
    setDelivery({});

    if (!customer) return;
    getAddresses(customer.value)
      .then((res) => {
        if (res.length) {
          setAddresses(addressesMap(res));

          setDelivery(DELIVERY.find((v) => v.value === 2));
          return;
        }

        setDelivery(DELIVERY.find((v) => v.value === 1));
      })
      .finally(() => setLoading(false));

    getContracts(customer.value)
      .then((res) => {
        setContracts(contractsMap(res));
        const contract = res.find((v) => v.main === true && v.active === true);
        if (contract) {
          setContract({ value: contract?.id, label: contract?.name });
        }
      })
      .finally(() => setLoading(false));
  }, [customer]);

  useEffect(() => {
    if (delivery.value !== 2) return;
    setAddress(addresses.find((address) => address.main) || addresses[0]);
    // eslint-disable-next-line
  }, [delivery]);

  useEffect(() => {
    setOrderError(false);
  }, [customer, delivery, address]);

  const orderHandler = () => {
    const noFilledProviders = cart.filter((val) => !val.provider);
    if (noFilledProviders.length) {
      setError(
        `Не заполнены поставщики у ${noFilledProviders.length} позиций в корзине`
      );
      return;
    }

    const deliverySettings = {};
    if (delivery.value === 2) {
      deliverySettings.address = address.value;
    }
    if (!orderValid()) {
      setOrderError(true);
      return false;
    }
    setLoading(true);

    createOrder({
      customer: customer.value,
      delivery: delivery.value,
      deliverySettings: address?.label || "",
      addressText,
      comment,
      sklad: sklad?.value || null,
      contract: contract.value || null,
    })
      .then((res) => {
        setOpenModal(true);
        setDelivery({});
        setAddress({});
        setCustomer({});
        setContracts([]);
        setContract({});
        setOrderSuccess(true);
        setOrder(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const orderExistHandler = () => {
    const noFilledProviders = cart.filter((val) => !val.provider);
    if (noFilledProviders.length) {
      setError(
        `Не заполнены поставщики у ${noFilledProviders.length} позиций в корзине`
      );
      return;
    }

    setLoading(true);
    createOrder({
      number: existOrder,
    })
      .then((res) => {
        if (res.duplicate) {
          alert(res.duplicate);
        }

        setOpenModal(true);
        setOrderSuccess(true);
        setOrder(res);
        localStorage.removeItem("customer");
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Card className="orderform">
        {loading && <MySpinner overlay />}
        {error && <Alert color="danger">{error}</Alert>}

        <CardBody>
          <h3 className="mb-4">Оформление заказа</h3>
          <Row>
            <Col lg="3">
              <SelectCustomer
                label={true}
                customer={customer}
                setCustomer={setCustomer}
                getAndSetPrice={getAndSetPrice}
              />
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Способ доставки</Label>
                <Select
                  placeholder="Выберите способ доставки"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={DELIVERY}
                  onChange={setDelivery}
                  value={delivery}
                  isSearchable={false}
                  isDisabled={customer?.value ? false : true}
                />
              </FormGroup>

              {delivery?.value === 2 && (
                <>
                  <FormGroup>
                    <Label>Выберите адрес доставки</Label>
                    <Select
                      placeholder="Выберите адрес доставки"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={addresses}
                      onChange={setAddress}
                      value={address}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Или введите его (он будет сохранен у клиента)</Label>
                    <Input
                      type="textarea"
                      value={addressText}
                      onChange={(e) => setAddressText(e.target.value)}
                      placeholder="Введите адрес доставки"
                    />
                  </FormGroup>
                </>
              )}
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Комментарий</Label>
                <Input
                  type="textarea"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Комментарий к заказу"
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Склад</Label>
                <Select
                  placeholder="Выберите склад"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={SKLAD}
                  onChange={setSklad}
                  value={sklad}
                  isSearchable={false}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Договор</Label>
                <Select
                  placeholder="Выберите договор"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={contracts}
                  onChange={setContract}
                  value={contract}
                  isSearchable={false}
                />
              </FormGroup>
            </Col>
          </Row>
          {orderError && (
            <span className="error">Необходимо заполнить все поля заказа</span>
          )}
          <Row>
            <Col lg="3">
              <Button
                color="primary"
                onClick={orderHandler}
                className="button-wide"
              >
                Создать заказ
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <InputGroup className="mt-3">
                <Input
                  value={existOrder}
                  onChange={(e) => setExistOrder(e.target.value)}
                  placeholder="Номер заказа"
                />
                <Button color="secondary" onClick={() => orderExistHandler()}>
                  Добавить в существующий заказ
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader>
          <div className="modal-title-text">Заказ</div>
          <CloseButton onClick={() => setOpenModal(false)} />
        </ModalHeader>
        <ModalBody className="text-center m-3">
          {existOrder ? (
            <h3 className="mb-1">Заказ № {existOrder} успешно обновлен.</h3>
          ) : (
            <>
              <h3 className="mb-1">Заказ успешно создан!</h3>
            </>
          )}

          <div className="d-flex justify-content-center">
            <Button
              onClick={() => setOpenModal(false)}
              className="mr-3"
              color="secondary"
            >
              Закрыть
            </Button>
            {order?.id && (
              <Link
                className="btn btn-primary"
                to={`/sales/orders/${order.id}`}
                color="primary"
              >
                Перейти в заказ
              </Link>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
