import { priceFormat } from "utils/format";

export const tableColumns = [
  {
    dataField: "name",
    text: "Имя",
    headerStyle: (colum, colIndex) => {
      return { width: '13%' };
    }
  },
  {
    dataField: "active",
    text: "Активен",
    headerStyle: (colum, colIndex) => {
      return { width: '5%' };
    },
    formatter: cell => cell ? 'Да' : 'Нет',
  },
  {
    dataField: "type",
    text: "Тип",
    headerStyle: (colum, colIndex) => {
      return { width: '5%' };
    },
    formatter: cell => {
      switch (cell) {
        case 1:
          return 'Физ';
        case 2:
          return 'Юр';
        default:
          return 'Физ';
      }
    },
  },
  {
    dataField: "orgInn",
    text: "ИНН",
    headerStyle: (colum, colIndex) => {
      return { width: '8%' };
    }
  },
  {
    dataField: "markup",
    text: "Наценка",
    headerStyle: (colum, colIndex) => {
      return { width: '5%' };
    },
    formatter: cell => `${cell} %`,
  },
  {
    dataField: "balance",
    text: "Баланс",
    headerStyle: (colum, colIndex) => {
      return { width: '7%' };
    },
    formatter: cell => <b className={cell < 0 ? 'red' : ''}>{priceFormat(cell)}</b>,
    headerClasses: () => 'right',
    classes: () => 'right'
  },
  {
    dataField: "email",
    text: "Email",
    headerStyle: (colum, colIndex) => {
      return { width: '13%' };
    }
  },
  {
    dataField: "phone",
    text: "Тел",
    headerStyle: (colum, colIndex) => {
      return { width: '13%' };
    }
  },
  {
    dataField: "admin",
    text: "Менеджер",
    headerStyle: (colum, colIndex) => {
      return { width: '13%' };
    }
  },

];