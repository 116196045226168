import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";

import { createUser, getAdmins, getUser, updateUser } from "services/api";
import { MySpinner } from "components/MySpinner";
import { priceFormat } from "utils/format";
import { Contracts } from "features/Users/Contracts";
import { Helmet } from "react-helmet";
import { Password } from "features/Users/Password";
import { Addresses } from "features/Users/Addresses";
import { MainForm } from "features/Users/MainForm";
import { LegalForm } from "features/Users/LegalForm";
import { HeaderPage } from "components/HeaderPage";
import { ActiveResource } from "components/ActiveResource";
import { validHandler } from "utils/validation";

import "./user.scss";
import { VALIDATION, VALIDATION_PRIVATE } from "./validationFields";

export const UserOnePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [user, setUser] = useState({
    admin: "",
    lastName: "",
    firstName: "",
    middleName: "",
    email: "",
    phone: "",
    type: 2,
    markup: 0,
    delayDayCount: 0,
    creditSumMax: 0,
    orgName: "",
    orgNameShort: "",
    orgAddress: "",
    orgInn: "",
    orgKpp: "",
    orgOgrn: "",
    orgRs: "",
    orgKs: "",
    orgBik: "",
    orgBank: "",
    genDir: "",
    dogovorAt: "",
    dogovorNumber: "",
  });
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [needValid, setNeedValid] = useState(false);
  const [errors, setErrors] = useState([]);

  const getAndSetUser = () => {
    if (!id) return;
    getUser(id)
      .then((res) => {
        setUser({ ...res, admin: res.admin?.id });
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getAndSetAdmins = () => {
    getAdmins()
      .then((res) => {
        setAdmins(
          res.map((item) => {
            return {
              value: item.id,
              label: `${item.lastName} ${item.firstName}`,
            };
          })
        );
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetUser();
    getAndSetAdmins();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeHandler = (e) => {
    const u = { ...user };
    u[e.target.name] = e.target.value;
    setUser(u);
  };

  const checkboxHandler = (e) => {
    const u = { ...user };
    u[e.target.name] = e.target.checked;

    setUser(u);
  };

  const selectHandler = (val, res) => {
    const u = { ...user };
    u[res.name] = val.value;
    setUser(u);
  };

  const renderHeaderName = () => {
    if (!loading && !user.id) {
      return "Новый клиент";
    }
    if (user.type === 1) {
      return `${user.lastName} ${user.firstName} ${user.middleName}`;
    }
    return user.orgNameShort;
  };

  useEffect(() => {
    const { errors } = validHandler(
      user,
      user.type === 2 ? VALIDATION : VALIDATION_PRIVATE
    );
    setErrors(errors);
    // eslint-disable-next-line
  }, [user]);

  const updateHandler = () => {
    setLoading(true);
    updateUser(user)
      .then((res) => {
        toast.success("Изменения успешно сохранены");
        setUser({ ...res });
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const createHandler = () => {
    setNeedValid(true);
    const { valid, errors } = validHandler(
      { ...user },
      user.type === 2 ? VALIDATION : VALIDATION_PRIVATE
    );

    setErrors(errors);

    if (!valid) return;

    setLoading(true);
    createUser(user)
      .then((res) => {
        toast.success("Изменения успешно сохранены");
        history.push(res.id);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container fluid className="user p-0">
      <Helmet>
        <title>{renderHeaderName()}</title>
      </Helmet>

      <HeaderPage title={renderHeaderName()}>
        <>
          {user.id && (
            <>
              <ActiveResource
                active={user.active}
                checkboxHandler={checkboxHandler}
              />

              <div className="user__balance">
                Баланс:{" "}
                <span className={classNames({ red: user.balance < 0 })}>
                  {priceFormat(user.balance)} р.
                </span>
              </div>
            </>
          )}
        </>
      </HeaderPage>

      {loading && <MySpinner overlay />}

      <MainForm
        user={user}
        changeHandler={changeHandler}
        selectHandler={selectHandler}
        admins={admins}
        needValid={needValid}
        errors={errors}
      />

      {user.type === 2 && (
        <LegalForm
          user={user}
          changeHandler={changeHandler}
        />
      )}

      <div className="user__save">
        <Button
          color="primary"
          onClick={user.id ? updateHandler : createHandler}
          className="button-wide"
        >
          Сохранить
        </Button>
      </div>

      {user.id && (
        <>
          <Addresses user={user} />

          {user.type === 2 && <Contracts user={user} />}
          <Password user={user} />
        </>
      )}
    </Container>
  );
};
