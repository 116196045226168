import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { priceFormat } from "utils/format";
import { CheckCircle } from "react-feather";

import user from "assets/img/user.svg";

import "./returnsCustomerTable.scss";

export const ReturnsCustomerTable = ({ returnsCustomer, rowEvents }) => {
  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      headerStyle: () => {
        return { width: "3%" };
      },
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "customer",
      text: "Клиент",
      headerStyle: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "orderNumber",
      text: "Заказ",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "admin",
      text: "Создал",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "4%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "4%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "9%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={returnsCustomer}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        classes="pointer"
        rowEvents={rowEvents}
      />
    </>
  );
};
