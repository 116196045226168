import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat, priceFormat } from "utils/format";

export const AnaliticsPriceTable = ({ analiticsPrice }) => {
  const tableColumns = [
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "name",
      text: "Наименование",
      headerStyle: () => {
        return { width: "18%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "percent",
      text: "Разница",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => <>{cell} %</>,
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "provider",
      text: "Поставщик",
      headerStyle: () => {
        return { width: "12%" };
      },
      formatter: (cell) => <span className="cute">{cell}</span>,
    },
    {
      dataField: "createdAt",
      text: "Дата",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell) => dateFormat(cell),
    },
    {
      dataField: "",
      text: "",
      headerStyle: () => {
        return { width: "20%" };
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={analiticsPrice}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
      />
    </>
  );
};
