import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";

import { getAdmins, getUsers } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";

import { tableColumns } from "./tableData";
import "./users.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

const SORT_FIELDS = [
  { label: "Баланс ↑", value: "balance_asc", by: "balance", dir: "asc" },
  { label: "Баланс ↓", value: "balance_desc", by: "balance", dir: "desc" },
];

export const UsersPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [admins, setAdmins] = useState([]);

  const [admin, setAdmin] = useState(
    query.get("admin")
      ? { label: query.get("adminName"), value: query.get("admin") }
      : { label: "", value: "" }
  );
  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
        (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
      )
    : { value: "", label: "" };
  const [sort, setSort] = useState(sortByDefault);

  const getAndSetUsers = () => {
    getUsers({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      sort,
      admin: admin?.value,
    })
      .then((res) => {
        setUsers(res.items);
        setUsersCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getAndSetAdmins = () => {
    getAdmins()
      .then((res) => {
        const adminsConvert = res.map((item) => {
          return {
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        setAdmins(adminsConvert);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    setLoading(true);

    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (sort.by && sort?.dir) {
      url.append("sortBy", sort.by);
      url.append("sortDir", sort.dir);
    }

    if (admin?.value) {
      url.append("admin", admin?.value);
      url.append("adminName", admin?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    getAndSetUsers();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort, admin]);

  useEffect(() => {
    if (!search) {
      setLoading(true);
      getAndSetUsers();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setLoading(true);
    getAndSetUsers();
    getAndSetAdmins();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/sales/users/${row.id}`);
    },
  };

  const searchHandler = () => {
    if (!search) return;
    setLoading(true);
    getAndSetUsers();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        setAdmin(val);
        break;
      case "sort":
        setSort(val);
        break;
      default:
        break;
    }
  };

  return (
    <Container fluid className="p-0 users">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Клиенты</h1>
          <Form className="mb-3">
            <Row>
              <Col mb="4" className="mb-1">
                <Label>Поиск</Label>
                <InputGroup>
                  <Input
                    name="search"
                    placeholder="По фамилии, email, фирме, ИНН"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  {search && (
                    <Button color="danger" onClick={() => setSearch("")}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button color="primary" onClick={searchHandler}>
                    Поиск
                  </Button>
                </InputGroup>
              </Col>

              <Col mb="2" className="mb-1">
                <Label>Менеджер</Label>
                <Select
                  name="admin"
                  placeholder="Ответственный менеджер"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  defaultValue={[{ value: "", label: "" }]}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>

              <Col mb="3" className="mb-1">
                <Label>Сортировка</Label>
                <InputGroup>
                  <Select
                    name="sort"
                    placeholder="Сортировка"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={SORT_FIELDS}
                    value={sort}
                    defaultValue={[{ value: "", label: "" }]}
                    onChange={selectHandler}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Button onClick={() => history.push("/sales/users/new")} color="primary">
            Добавить клиента
          </Button>
        </CardBody>
      </Card>
      <div>
        <BootstrapTable
          keyField="id"
          data={users}
          columns={tableColumns}
          bootstrap4
          bordered={false}
          hover={true}
          rowEvents={rowEvents}
        />
        <Card>
          <CardBody>
            <Pagination
              totalCount={usersCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
