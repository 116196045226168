import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useQuery from "utils/useQuery";
import { Pagination } from "components/Pagination";
import { getAdmins, getReturnsCustomer } from "services/api";
import { ReturnsCustomerTable } from "features/ReturnsCustomer/ReturnsCustomerTable";

import "./returnsCustomer.scss";

export const ReturnsCustomerPage = () => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const [returnsCustomer, setReturnsCustomer] = useState([]);
  const [returnsCustomerCount, setReturnsCustomerCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [admin, setAdmin] = useState(null);
  const [search, setSearch] = useState("");
  const [number, setNumber] = useState("");
  const [isShowReset, setIsShowReset] = useState(false);
  const [ctrl, setCtrl] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(10);

  const getAndSetReturnsCustomer = () => {
    getReturnsCustomer({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      number,
    })
      .then((res) => {
        setReturnsCustomer(res.items);
        setReturnsCustomerCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getAndSetAdmins = () => {
    getAdmins()
      .then((res) => {
        const adminsConvert = res.map((item) => {
          return {
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        setAdmins(adminsConvert);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getAndSetAdmins();
    getAndSetReturnsCustomer();

    const onKeyDown = (e) => e.keyCode === 17 && setCtrl(true);
    const onKeyUp = (e) => e.keyCode === 17 && setCtrl(false);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      if (ctrl) {
        window.open(`/sales/returnsFromCustomer/${row.id}`, "_blank");
        return;
      }
      history.push(`/sales/returnsFromCustomer/${row.id}`);
    },
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        setAdmin(val);
        break;
      default:
        break;
    }
  };

  const isSearch = () => {
    if (search || number || admin?.value) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    setLoading(true);
    getAndSetReturnsCustomer();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetReturnsCustomer();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, number, admin]);

  const resetFilter = () => {
    setSearch("");
    setNumber("");
    setAdmin({ label: "", value: "" });
  };

  const numberHandler = (e) => {
    setNumber(e.target.value.replace(/[^+\d]/g, ""));
  };

  return (
    <Container fluid className="p-0 returns-to-provider">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Возвраты от клиента</h1>

          <Form className="mb-3">
            <Row>
              <Col>
                <Label>Номер</Label>
                <Input
                  name="number"
                  placeholder="По номеру"
                  value={number}
                  onChange={numberHandler}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={3}>
                <Label>Создал</Label>
                <Select
                  name="admin"
                  placeholder="Сотрудник"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  defaultValue={[{ value: "", label: "" }]}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md="2">
              <Link
                to={`/returnsFromCustomer/new`}
                color="primary"
                className="button-wide btn btn-primary"
              >
                Добавить
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <ReturnsCustomerTable
          returnsCustomer={returnsCustomer}
          rowEvents={rowEvents}
        />

        <Card>
          <CardBody>
            <Pagination
              totalCount={returnsCustomerCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
