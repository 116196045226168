import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { CloseButton } from "components/CloseButton";

export const GetRelatedDocumentsModal = ({ openModal, setOpenModal, row }) => {
  const summ = () => {
    return row.relatedDocuments?.reduce((acc, val) => acc + val.count, 0);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">
          Связанные документы
          <span className="modal-title-small">ID: {row.id}</span>
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        {row.relatedDocuments?.map((val) => (
          <Row key={val.id}>
            <Col lg="3">{val.document}</Col>
            <Col lg="8">{val.count}</Col>
          </Row>
        ))}
        <Row>
          <Col lg="3">Итого: </Col>
          <Col lg="8">{summ()}</Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
