import React from "react";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

import { dateTimeFormat, priceFormat } from "utils/format";

import user from "assets/img/user.svg";
import "./ordersToProviderTable.scss";

export const OrdersToProviderTable = ({ products }) => {
  const history = useHistory();
  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "date",
      text: "Дата",
      headerStyle: () => {
        return { width: "7%" };
      },
      formatter: (cell) => dateTimeFormat(cell),
    },
    {
      dataField: "provider",
      text: "Поставщик",
      headerStyle: () => {
        return { width: "13%" };
      },
      classes: () => "bold",
    },
    {
      dataField: "admin",
      text: "Создал",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "4%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "4%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/receipt/supply/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={products}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
