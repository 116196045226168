import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Status } from "components/Status";
import BootstrapTable from "react-bootstrap-table-next";
import { CheckCircle } from "react-feather";

import { dateFormat, priceFormat } from "utils/format";
import { CustomerModalButton } from "../CustomerModalButton";

import "./ordersTable.scss";
import user from "assets/img/user.svg";

export const OrdersTable = ({ orders, statusHandler }) => {
  const history = useHistory();
  const [ctrl, setCtrl] = useState(false);

  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      headerStyle: () => {
        return { width: "3%" };
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "date",
      text: "Дата зак.",
      formatter: (cell) => dateFormat(cell),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "shippingDate",
      text: "Дата реал.",
      formatter: (cell) => dateFormat(cell),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "customer",
      text: "Контрагент",
      headerStyle: () => {
        return { width: "13%" };
      },
      classes: () => "bold",
      formatter: (cell, row) => <CustomerModalButton order={row} />,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "4%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "status",
      text: "Статус",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => (
        <Status order={row} statusHandler={statusHandler}>
          {cell.name}
        </Status>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "payment",
      text: "Тип оплаты",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => <>{cell === 1 ? "Нал" : "Безнал"}</>,
    },
    {
      dataField: "admin",
      text: "Менеджер",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
    {
      dataField: "phone",
      text: "Телефон",
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "9%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
  ];

  useEffect(() => {
    const onKeyDown = (e) => e.keyCode === 17 && setCtrl(true);
    const onKeyUp = (e) => e.keyCode === 17 && setCtrl(false);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      if (ctrl) {
        window.open(`/sales/orders/${row.id}`, "_blank");
        return;
      }

      history.push(`/sales/orders/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={orders}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
