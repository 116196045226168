import { ButtonChangeCart } from "features/Dashboard/ButtonChangeCart";
import { priceFormat } from "utils/format";
import { ChangeProviderCart } from "../ChangeProviderCart";

//import { PriceCell } from "../PriceCell";

export const tableColumns = [
  {
    dataField: "article",
    text: "Артикул",
    headerStyle: (colum, colIndex) => {
      return { width: "13%" };
    },
    footer: () => null,
    editable: false,
  },
  {
    dataField: "brand",
    text: "Бренд",
    headerStyle: (colum, colIndex) => {
      return { width: "13%" };
    },
    footer: () => null,
    editable: false,
  },
  {
    dataField: "name",
    text: "Наименование",
    headerStyle: (colum, colIndex) => {
      return { width: "27%" };
    },
    formatter: (cell, row) => <span className="cute">{cell}</span>,
    footer: () => null,
    editable: false,
  },
  {
    dataField: "price",
    text: "Цена",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "6%" };
    },
    formatter: (cell, row) => priceFormat(cell),
    classes: (cell, row, rowIndex, colIndex) => "editable right",
    headerClasses: () => "right",
    footerClasses: () => "right",
    footer: () => null,
  },
  {
    dataField: "count",
    text: "Кол.",
    headerStyle: (colum, colIndex) => {
      return { width: "5%" };
    },
    classes: () => "right",
    headerClasses: () => "right",
    footerClasses: () => "right",
    footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    editable: false,
  },
  {
    dataField: "summ",
    text: "Сумма",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "6%" };
    },
    formatter: (cell, row) => priceFormat(cell),
    classes: () => "right",
    headerClasses: () => "right",
    footerClasses: () => "right",
    footer: (columnData) => {
      const total = columnData.reduce((acc, item) => acc + parseInt(item), 0);
      return total && priceFormat(total);
    },
    editable: false,
  },
  {
    dataField: "exist",
    text: "Нал.",
    headerStyle: (colum, colIndex) => {
      return { width: "6%" };
    },
    classes: (cell, row, rowIndex, colIndex) => "right",
    headerClasses: () => "right",
    footerClasses: () => "right",
    footer: () => null,
    editable: false,
  },
  {
    dataField: "provider",
    text: "Поставщик",
    headerStyle: (colum, colIndex) => {
      return { width: "12%" };
    },
    classes: (cell) => (!cell?.id ? "cart__error" : ""),
    formatter: (cell, row) => <ChangeProviderCart row={row} />,
    footer: () => null,
    editable: false,
  },
  {
    dataField: "action",
    text: "Действия",
    headerStyle: (colum, colIndex) => {
      return { width: "152px" };
    },
    formatter: (_, row) => <ButtonChangeCart row={row} />,
    footer: () => null,
    editable: false,
  },
];
