import React, { useState, useEffect } from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import classNames from "classnames";

import { updateNomenclature } from "services/api";
import { dateFormat, priceFormat, dateTimeFormat } from "utils/format";
import { searchPrice } from "services/api";
import { PROVIDERS } from "pages/dashboard";
import { updateBestPrice } from "services/apiAnalitics";

import "./analiticsSale.scss";

export const AnaliticsSaleTable = ({ analiticsSale, onSort }) => {
  const sortResult = (items) => {
    return items.sort(function (a, b) {
      if (a.sort > b.sort) {
        return 1;
      }
      if (a.sort < b.sort) {
        return -1;
      }
      return 0;
    });
  };

  const tableColumns = [
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "name",
      text: "Наименование",
      headerStyle: () => {
        return { width: "18%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
      editable: false,
    },
    {
      dataField: "provider",
      text: "Пост-к",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
      editable: false,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "costPrice",
      text: "Себ-ть",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
    },
    {
      dataField: "addExpenses",
      text: "Доп расх",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell, row) => "right",
    },
    {
      dataField: "margin",
      text: "Маржа",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => <>{cell} %</>,
      headerClasses: () => "right",
      classes: (cell, row) => "right",
    },
    {
      dataField: "countOrders",
      text: "Кол-во док",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: (cell, row) => "right",
    },
    {
      dataField: "inStock",
      text: "Остаток",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: (cell, row) => "right",
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={analiticsSale}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
      />
    </>
  );
};
