import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import classNames from "classnames";
import {
  Button,
  Container,
  Input,
  Row,
  Label,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
  Alert,
} from "reactstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CheckCircle, Check } from "react-feather";

import {
  getAddresses,
  getAdmins,
  getCheck,
  getInvoice,
  getOrder,
  getStatuses,
  getUpd,
  sendToDiadoc,
  updateOrder,
  baseUrl,
  getContracts,
  setStatus,
  createReserve,
  carryOutOrder,
  addBonus,
  updateComment,
} from "services/api";
import { MySpinner } from "components/MySpinner";
import { Status } from "components/Status";
import { dateFormat, dateTimeFormat, priceFormat } from "utils/format";
import { useOrders } from "features/Orders/OrdersContext";
import { DELIVERY, SKLAD } from "features/Dashboard/OrderForm";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { SendInvoiceModal } from "features/Orders/SendInvoiceModal";
import { LabelModal } from "features/Orders/LabelModal";
import { ChangeLogOrders } from "features/Orders/ChangeLogOrders";
import { OrderProductTable } from "features/Orders/OrderProductTable";
import { HeaderPage } from "components/HeaderPage";
import { Checkbox } from "components/Checkbox";
import { useUser } from "features/Users/UserContext";

import "./orderOne.scss";

export const OrderOnePage = () => {
  const { id } = useParams();
  const { setStatuses, status, setLoading, loading, setProducts, products } =
    useOrders();
  const { user } = useUser();
  const [order, setOrder] = useState({});
  const [delivery, setDelivery] = useState("");
  const [comment, setComment] = useState("");
  const [customer, setCustomer] = useState(null);
  const [deliverySettings, setDeliverySettings] = useState("");
  const [shippingDate, setShippingDate] = useState("");
  const [admins, setAdmins] = useState([]);
  const [admin, setAdmin] = useState(null);
  const [sklad, setSklad] = useState(null);
  const [contract, setContract] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [date, setDate] = useState("");
  const [regulated, setRegulated] = useState(true);
  const [bonus, setBonus] = useState(0);
  const [openModalLabel, setOpenModalLabel] = useState(false);
  const [noEdit, setNoEdit] = useState(false);
  const [error, setError] = useState("");

  const getAndSetStatuses = () => {
    getStatuses()
      .then((res) => {
        setStatuses(res);
      })
      .catch((e) => console.error(e));
  };

  const getAndSetAdmins = () => {
    getAdmins()
      .then((res) => {
        const getAdmins = res.map((item) => {
          return {
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        setAdmins(getAdmins);
      })
      .catch((e) => console.error(e));
  };

  const getAndSetOrder = () => {
    getOrder(id)
      .then((res) => {
        setOrder(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetStatuses();
    getAndSetAdmins();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDelivery(order.delivery?.id);
    setComment(order.comment || "");
    setCustomer({ value: order.customer?.id, label: order.customer?.name });
    if (order.admin) {
      setAdmin({ value: order.admin?.id, label: order.admin?.name });
    }

    if (order.sklad) {
      setSklad(SKLAD.find((val) => order.sklad === val.value));
    }

    setContracts(
      order.customer?.contracts.map((val) => {
        return {
          label: val.label,
          value: val.id,
        };
      })
    );

    if (order.contract) {
      setContract({ value: order.contract?.id, label: order.contract?.name });
    }

    setDate(dateFormat(order.date));
    setProducts(order.products);
    setDeliverySettings(order.deliverySettings || "");
    setShippingDate(dateTimeFormat(order.shippingDate));
    setRegulated(order.regulated);
    setBonus(order.bonus || "");

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    getAndSetOrder();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    console.log(regulated);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regulated]);

  const orderHandler = () => {
    if (!order.id) return;
    setLoading(true);
    updateOrder({
      id: order.id,
      delivery,
      comment,
      customer: customer?.value,
      admin: admin?.value,
      date,
      deliverySettings,
      shippingDate,
      sklad: sklad?.value,
      contract: contract?.value,
      regulated,
      bonus: parseInt(bonus),
    })
      .then((res) => {
        setOrder(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "delivery":
        setDelivery(val.value);
        break;
      case "admin":
        setAdmin(val);
        break;
      case "sklad":
        setSklad(val);
        break;
      case "contract":
        setContract(val);
        break;
      default:
        break;
    }
  };

  const changeHandler = (e) => {
    switch (e.target.name) {
      case "comment":
        setComment(e.target.value);
        break;
      case "bonus":
        setBonus(e.target.value);
        break;
      case "date":
        setDate(e.target.value);
        break;
      case "shippingDate":
        setShippingDate(e.target.value);
        break;
      case "deliverySettings":
        setDeliverySettings(e.target.value);
        break;
      default:
        break;
    }
  };

  const diadocHandler = () => {
    if (!id) return;
    setLoading(true);
    sendToDiadoc({ id })
      .then((res) => {
        toast.success("Документ отправлен в диадок");
        if (res.message) {
        }
        setError("");
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          setError(e.response?.data?.message);
        } else {
          setError("Ошибка при отправке в диадок");
        }
      })
      .finally(() => setLoading(false));
  };

  const updHandler = () => {
    getUpd({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `УПД № ${order.number} от ${order.shippingDateText}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const invoiceHandler = (withSign = false) => {
    getInvoice({
      id,
      withSign: withSign ? 1 : 0,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Счет № ${order.number}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkHandler = () => {
    getCheck({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Чек № ${order.number}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validProvider = () => {
    return products.find((val) => !val.provider);
  };

  const setBonusHandler = () => {
    setLoading(true);
    addBonus({
      id,
      bonus,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Бонус успешно сохранен");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setCommentHandler = () => {
    setLoading(true);
    updateComment({
      id,
      comment,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Комментарий успешно сохранен");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (order.status?.sort >= 4) {
      setNoEdit(true);
    } else {
      setNoEdit(false);
    }
  }, [order.status]);

  const contractsMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.name, main: item.main };
    });

  const customerHandler = (val) => {
    setCustomer(val);
    setDeliverySettings("");
    setLoading(true);
    getAddresses(val.value)
      .then((res) => {
        if (res.length) {
          const address = res.find((address) => address.main) || res[0];
          setDeliverySettings(address.address);
          setDelivery(2);
          return;
        }
        setDelivery(1);
      })
      .finally(() => setLoading(false));

    getContracts(val.value)
      .then((res) => {
        setContracts(contractsMap(res));

        if (res.length > 0) {
          const contract = res.find((v) => v.main === true) || res[0];

          if (contract) {
            setContract({ value: contract?.id, label: contract?.name });
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const statusHandler = (status, order) => {
    setLoading(true);
    setStatus({
      orderId: order?.id,
      statusId: status?.id,
    })
      .then(() => {
        getAndSetOrder();
        toast.success("Товар успешно изменен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const reserveHandler = () => {
    setLoading(true);
    createReserve({
      id,
    })
      .then(() => {
        getAndSetOrder();
        toast.success("Товар успешно зарезервирован");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    if (!order.carriedOut) {
      for (const row of products) {
        const providerStock = row.providersStock.find(
          (val) => val.id === row.provider?.id
        );

        if (
          !providerStock &&
          row.provider?.id !== "518680fd-6d10-4543-96a4-108534ba69ca"
        ) {
          toast.error("Не все поставщики проставлены правильно");
          return;
        }
      }
    }

    setLoading(true);
    carryOutOrder({
      id,
      carryOut: order.carriedOut ? 0 : 1,
      shippingDate,
    })
      .then((res) => {
        getAndSetOrder();
        if (res.carriedOut) {
          toast.success("Заказ успешно проведен");
        } else {
          toast.success("Заказ успешно распроведен");
        }
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(true);
        getAndSetOrder();
      });
  };

  return (
    <Container fluid className="p-0 order">
      <Helmet>
        <title>
          Заказ №{`${order.number || ""} от ${order.customer?.name}`}
        </title>
      </Helmet>

      <HeaderPage title={`Заказ № ${order.number}`}>
        <>
          <div className="order__date-block">
            <Label>Дата</Label>
            <ButtonGroup>
              <InputMask
                id="date"
                name="date"
                type="text"
                onChange={changeHandler}
                value={date}
                mask={"99.99.9999"}
                className="form-control date"
              />
              <ChangeLogOrders id={id} />
            </ButtonGroup>
          </div>

          {(order.status?.sort === 5 || order.status?.sort === 6) && (
            <div className="order__carried-out-button">
              <div className="order__date-block shipping-date">
                <Label>Дата реализации</Label>
                <InputMask
                  id="shippingDate"
                  name="shippingDate"
                  type="text"
                  onChange={changeHandler}
                  value={shippingDate}
                  mask={"99.99.9999 99:99:99"}
                  className="form-control date"
                />
              </div>

              {order.carriedOut && <CheckCircle color="green" />}
              <Button
                color="primary"
                onClick={carryOutHandler}
                className="button-wide ml-1"
              >
                {order.carriedOut ? `Распровести заказ` : `Провести заказ`}
              </Button>
            </div>
          )}
        </>
      </HeaderPage>

      {loading && <MySpinner overlay />}

      {error && <Alert color="danger">{error}</Alert>}

      <Row className="elem">
        <div className="elem-field">
          <Label>Клиент</Label>
          <SelectCustomer customer={customer} setCustomer={customerHandler} />
        </div>
        <div>
          <div className="balance">
            Баланс клиента:{" "}
            <b
              className={classNames({
                red: order.customer?.balance < 0,
                green: order.customer?.balance > 0,
              })}
            >
              {priceFormat(order.customer?.balance)} р
            </b>
          </div>
        </div>
      </Row>
      <Row className="elem">
        <div className="elem-field">
          <Label>Ответственный менеджер</Label>
          <Select
            name="admin"
            placeholder="Ответственный менеджер"
            className="react-select-container"
            classNamePrefix="react-select"
            options={admins}
            value={admin}
            onChange={selectHandler}
          />
        </div>
        <div className="elem-field">
          <Label>Бонус</Label>
          <ButtonGroup>
            <Input
              name="bonus"
              placeholder="Бонус"
              onChange={changeHandler}
              value={bonus}
            />
            <Button onClick={setBonusHandler}>
              <Check size={15} />
            </Button>
          </ButtonGroup>
        </div>
        {order.customer?.type == 2 && (
          <div className="elem-field">
            <Label>Регл. учет</Label>
            <Checkbox
              name="regulated"
              checked={regulated}
              onChange={(e) => {
                if (e.target.checked === false) {
                  if (
                    window.confirm(
                      "Если убрать галочку, то реализация не выгрузится в 1С!"
                    )
                  ) {
                    setRegulated(false);
                    return;
                  }
                  setRegulated(true);
                  return;
                }
                setRegulated(true);
              }}
            />
          </div>
        )}
      </Row>
      <Row className="elem">
        <div className="elem-field">
          <Label>Способ доставки</Label>
          <Select
            name="delivery"
            placeholder="Способ доставки"
            className="react-select-container"
            classNamePrefix="react-select"
            options={DELIVERY}
            value={DELIVERY.find((val) => delivery === val.value)}
            onChange={selectHandler}
          />
        </div>
        <div className="elem-field delivery-settings">
          <Label>Адрес доставки</Label>
          <Input
            type="textarea"
            name="deliverySettings"
            placeholder="Адрес доставки"
            onChange={changeHandler}
            value={deliverySettings}
          />
        </div>
      </Row>

      <Row className="elem">
        <div className="elem-field contract">
          <Label>Договор</Label>
          <Select
            name="contract"
            placeholder="Договор"
            className="react-select-container"
            classNamePrefix="react-select"
            options={contracts}
            value={contract}
            onChange={selectHandler}
          />
        </div>
      </Row>

      <Row className="elem bottom">
        <div className="elem-field comment">
          <Label>Комментарий</Label>

          <Input
            type="textarea"
            name="comment"
            placeholder="Комментарий"
            onChange={changeHandler}
            value={comment}
          />
          {order.carriedOut && (
            <Button
              onClick={setCommentHandler}
              color="primary"
              className="mt-1"
            >
              Сохранить комментарий
            </Button>
          )}
        </div>

        <div className="button-block">
          <Status order={order} statusHandler={statusHandler}>
            {order.status?.name}
          </Status>
          <SendInvoiceModal id={id} defaultEmail={order.customer?.email} />
          <Button
            color="primary"
            onClick={diadocHandler}
            className="button-wide"
          >
            Выгрузить в Диадок
          </Button>

          {!noEdit && (
            <Button
              color="primary"
              onClick={reserveHandler}
              className="button-wide"
            >
              Резерв
            </Button>
          )}

          <UncontrolledButtonDropdown className="download">
            <DropdownToggle className="dropdown-toggle button-wide">
              Скачать
            </DropdownToggle>
            <DropdownMenu>
              {order.customer?.type === 2 ? (
                <>
                  <DropdownItem onClick={() => invoiceHandler(true)}>
                    Счет с печатью
                  </DropdownItem>
                  <DropdownItem onClick={() => invoiceHandler(false)}>
                    Счет без печати
                  </DropdownItem>
                </>
              ) : (
                <DropdownItem onClick={checkHandler}>Чек</DropdownItem>
              )}
              <DropdownItem>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${baseUrl}/orderPicking.print?id=${id}&admin=${user.id}`}
                >
                  Сборочную ведомость
                </a>
              </DropdownItem>
              {order.carriedOut && (
                <DropdownItem onClick={updHandler}>УПД</DropdownItem>
              )}

              <DropdownItem>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${baseUrl}/orders.getOffer?id=${order.id}`}
                >
                  Комм. предложение
                </a>
              </DropdownItem>

              <DropdownItem onClick={() => setOpenModalLabel(true)}>
                Этикетки
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>

        {!noEdit && (
          <div className="button-block">
            <Button onClick={orderHandler} color="primary button-wide">
              Сохранить
            </Button>
          </div>
        )}
      </Row>

      <OrderProductTable order={order} noEdit={noEdit} />

      <LabelModal
        id={id}
        openModal={openModalLabel}
        setOpenModal={setOpenModalLabel}
      />
    </Container>
  );
};
