import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Input, Table } from "reactstrap";
import { Trash } from "react-feather";
import { toast } from "react-toastify";

import { addAddress, removeAddress, setDefaultAddress } from "services/api";
import { TableButton } from "components/TableButton";
import { Checkbox } from "components/Checkbox";
import { FormTitle } from "components/FormTitle";
import { MySpinner } from "components/MySpinner";

import "./addresses.scss";

export const Addresses = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [showAddAddress, setShowAddAddress] = useState(false);

  useEffect(() => {
    setAddresses(user.addresses || []);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const addAddressHandler = () => {
    setLoading(true);
    addAddress({
      customer: user.id,
      address,
    })
      .then((res) => {
        setAddress("");
        setAddresses(res || []);
        setShowAddAddress(false);
        toast.success("Адрес успешно добавлен");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при добавлении адреса"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeAddressHandler = (id) => {
    setLoading(true);
    removeAddress({ customer: user.id, id })
      .then((res) => {
        setAddresses(res || []);
        toast.success("Адрес успешно удален");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message || "Ошибка при удалении адреса");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const defaultAddressHandler = (val, id) => {
    if (!val) return;
    setLoading(true);
    setDefaultAddress({ id })
      .then((res) => {
        setAddresses(res || []);
        toast.success("Данные успешно обновлены");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении данных"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderAddressesTable = () => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Адрес</th>
            <th className="center">Основной</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {addresses.map((item) => (
            <tr key={item.id}>
              <td>{item.address}</td>
              <td className="center">
                <Checkbox
                  value={item.id}
                  checked={item.main || false}
                  onChange={(val) => defaultAddressHandler(val, item.id)}
                />
              </td>
              <td>
                <TableButton
                  Icon={Trash}
                  onClick={() => removeAddressHandler(item.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="addresses">
      <Card className="mb-0">
        <CardBody className="pb-2">
          <FormTitle>Адреса доставки</FormTitle>
        </CardBody>
      </Card>

      {loading && <MySpinner overlay />}

      <div className="addresses__table">
        <div className="addresses__header"></div>
        {renderAddressesTable()}
        <div className="addresses__add">
          <Card className="mb-0">
            <CardBody>
              {showAddAddress ? (
                <>
                  <Input
                    type="textarea"
                    className="addresses__textarea"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                  <div className="buttons">
                    <Button
                      color="primary"
                      onClick={addAddressHandler}
                      className="addresses__save"
                    >
                      Сохранить адрес
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => setShowAddAddress(false)}
                    >
                      Отменить
                    </Button>
                  </div>
                </>
              ) : (
                <Button onClick={() => setShowAddAddress(true)} color="primary">
                  Добавить адрес
                </Button>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
