import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getProvider, updateProvider, createProvider } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Helmet } from "react-helmet";
import { HeaderPage } from "components/HeaderPage";
import { ActiveResource } from "components/ActiveResource";
import { VALIDATION } from "./validationFields";
import { validHandler } from "utils/validation";

import "./provider.scss";
import { LegalForm } from "features/Users/LegalForm";

export const ProviderOnePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [provider, setProvider] = useState({
    active: true,
    name: "",
    deliveryTime: "",
    probability: "",
    address: "",
    contacts: "",
    important: "",
    orgInn: "",
    orgName: "",
    orgNameShort: "",
    orgAddress: "",
    consigneeAddress: "",
    orgKpp: "",
    orgOgrn: "",
    orgOkpo: "",
    orgRs: "",
    orgKs: "",
    orgBik: "",
    orgBank: "",
    genDir: "",
    genDirGenitive: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);

  const getAndSetProvider = () => {
    getProvider(id)
      .then((res) => {
        setProvider(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAndSetProvider();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeHandler = (e) => {
    const u = { ...provider };
    u[e.target.name] = e.target.value;
    setProvider(u);
  };

  const checkboxHandler = (e) => {
    const u = { ...provider };
    u[e.target.name] = e.target.checked;
    setProvider(u);
  };

  useEffect(() => {
    console.log(provider);

    const { errors } = validHandler(provider, VALIDATION);
    setErrors(errors);
    // eslint-disable-next-line
  }, [provider]);

  const updateHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler(provider, VALIDATION);

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    if (!id) {
      createProvider(provider)
        .then((res) => {
          history.push(res.id);
          toast.success("Поставщик успешно создан");
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    } else {
      updateProvider(provider)
        .then((res) => {
          setProvider(res);
          toast.success("Изменения успешно сохранены");
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Container fluid className="p-0 provider">
      <Helmet>
        <title>Поставщик {provider.name || "новый"}</title>
      </Helmet>

      <HeaderPage title={`Поставщик ${provider.name || "новый"}`}>
        <ActiveResource
          active={provider.active}
          checkboxHandler={checkboxHandler}
        />
      </HeaderPage>

      {loading && <MySpinner overlay />}

      <Card className="main-form mb-0">
        <CardBody>
          <Form>
            <Row>
              <Col md="8" className="elem-field">
                <Label className="text-sm-right">
                  Внутреннее наименование (псевдоним)
                </Label>
                <Input
                  name="name"
                  placeholder="Внутреннее наименование (псевдоним)"
                  value={provider.name || ""}
                  onChange={changeHandler}
                  invalid={needValid && errors.hasOwnProperty("name")}
                />
              </Col>
              <Col md="4" className="elem-field">
                <Label className="text-sm-right">ИНН</Label>
                <Input
                  name="orgInn"
                  placeholder="ИНН"
                  value={provider.orgInn || ""}
                  onChange={changeHandler}
                  invalid={needValid && errors.hasOwnProperty("orgInn")}
                />
              </Col>
            </Row>

            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">Срок поставки, ч</Label>
                <Input
                  name="deliveryTime"
                  placeholder="Срок поставки"
                  value={provider.deliveryTime || ""}
                  onChange={changeHandler}
                  invalid={needValid && errors.hasOwnProperty("deliveryTime")}
                />
              </Col>
              <Col className="elem-field">
                <Label className="text-sm-right">Вероятность поставки, %</Label>
                <Input
                  name="probability"
                  placeholder="Вероятность поставки"
                  value={provider.probability || ""}
                  onChange={changeHandler}
                  invalid={needValid && errors.hasOwnProperty("probability")}
                />
              </Col>
            </Row>

            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">Адрес</Label>
                <Input
                  type="textarea"
                  name="address"
                  placeholder="Адрес"
                  value={provider.address || ""}
                  onChange={changeHandler}
                />
              </Col>
            </Row>
            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">Контакты</Label>
                <Input
                  type="textarea"
                  name="contacts"
                  placeholder="Контакты"
                  value={provider.contacts || ""}
                  onChange={changeHandler}
                />
              </Col>
            </Row>
            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">Важная информация</Label>
                <Input
                  type="textarea"
                  name="important"
                  placeholder="Важная информация"
                  value={provider.important || ""}
                  onChange={changeHandler}
                />
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <LegalForm user={provider} changeHandler={changeHandler} />

      <div className="provider__save">
        <Button
          color="primary"
          onClick={updateHandler}
          className="button-wide mr-3"
        >
          Сохранить
        </Button>
      </div>
    </Container>
  );
};
