export const VALIDATION = [
  'firstName',
  'email',
  'phone',
  'type',
  'orgName',
  'orgNameShort',
  'orgAddress',
  'orgInn',
  'orgKpp',
  'orgOgrn',
  'orgOkpo',
  'orgRs',
  'orgKs',
  'orgBik',
  'orgBank',
  'genDir',
  'inn'
];

export const VALIDATION_PRIVATE = [
  'firstName',
  'email',
  'phone',
  'type'
];