import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getCorrOrderListToExcel, getCorrOrders } from "services/apiSupply";
import useQuery from "utils/useQuery";
import { getAdmins } from "services/api";
import { CorrOrdersTable } from "features/CorrOrders/CorrOrdersTable";
import { Pagination } from "components/Pagination";
import { Checkbox } from "components/Checkbox";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { dateFormatU, dateTimeFormat, priceFormat } from "utils/format";

import "./corrOrders.scss";

registerLocale("ru", ru);

export const CorrOrdersPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [corrOrders, setCorrOrders] = useState([]);
  const [corrOrdersCount, setCorrOrdersCount] = useState(0);
  const [corrOrdersSumm, setCorrOrdersSumm] = useState(0);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [admin, setAdmin] = useState(
    localStorage.getItem("filterAdmin")
      ? JSON.parse(localStorage.getItem("filterAdmin"))
      : null
  );
  const [search, setSearch] = useState(query.get("search") || "");
  const [number, setNumber] = useState(query.get("number") || "");
  const [carriedOut, setCarriedOut] = useState(
    query.get("carriedOut") || false
  );
  const [corrNumber, setCorrNumber] = useState(query.get("corrNumber") || "");

  const [customer, setCustomer] = useState(
    query.get("customer")
      ? { label: query.get("customerName"), value: query.get("customer") }
      : null
  );

  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );

  const [isShowReset, setIsShowReset] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (customer?.value) {
      url.append("customer", customer?.value);
      url.append("customerName", customer?.label);
    }

    if (admin?.value) {
      url.append("admin", admin?.value);
      url.append("adminName", admin?.label);
    }

    if (number) {
      url.append("number", number);
    }

    if (corrNumber) {
      url.append("corrNumber", corrNumber);
    }

    if (carriedOut) {
      url.append("carriedOut", carriedOut);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    customer?.valuer,
    number,
    admin?.value,
    corrNumber,
    carriedOut,
    dateStart,
    dateEnd,
  ]);

  const getAndSetCorrOrders = () => {
    getCorrOrders({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      number,
      customer: customer?.value,
      admin: admin?.value,
      corrNumber,
      carriedOut: carriedOut ? 1 : 0,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
    })
      .then((res) => {
        setCorrOrders(res.items);
        setCorrOrdersCount(res.totalItems);
        setCorrOrdersSumm(res.totalSumm);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getAndSetAdmins = () => {
    getAdmins()
      .then((res) => {
        const adminsConvert = res.map((item) => {
          return {
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        setAdmins(adminsConvert);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getAndSetAdmins();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    getAndSetCorrOrders();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        setAdmin(val);
        break;
      default:
        break;
    }
  };

  const isSearch = () => {
    if (
      search ||
      number ||
      admin?.value ||
      customer?.value ||
      corrNumber ||
      carriedOut ||
      dateStart ||
      dateEnd
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    setLoading(true);
    getAndSetCorrOrders();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetCorrOrders();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    number,
    admin?.value,
    customer?.value,
    corrNumber,
    carriedOut,
    dateStart,
    dateEnd,
  ]);

  const resetFilter = () => {
    setSearch("");
    setNumber("");
    setCorrNumber("");
    setAdmin(null);
    setCustomer(null);
    setCarriedOut(false);
    setDateStart(null);
    setDateEnd(null);
  };

  const numberHandler = (e) => {
    setNumber(e.target.value.replace(/[^+\d]/g, ""));
  };

  const downloadCorrOrdersToExcel = () => {
    setLoading(true);
    getCorrOrderListToExcel({
      search,
      number,
      customer: customer?.value,
      admin: admin?.value,
      corrNumber,
      carriedOut: carriedOut ? 1 : 0,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Корректировки реализаций_${dateTimeFormat(new Date())}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container fluid className="p-0 corr-orders">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Корректировки реализаций</h1>

          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Дата</Label>
                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>

              <Col md={2}>
                <Label>Номер</Label>
                <Input
                  name="number"
                  placeholder="По порядк.номеру"
                  value={number}
                  onChange={numberHandler}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={2}>
                <Label>Номер корр</Label>
                <Input
                  name="number"
                  placeholder="По номеру корр"
                  value={corrNumber}
                  onChange={(e) => setCorrNumber(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={2}>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={3}>
                <Label>Создал</Label>
                <Select
                  name="admin"
                  placeholder="Создал корректировку"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Клиент</Label>
                <SelectCustomer customer={customer} setCustomer={setCustomer} />
              </Col>
              <Col md={2}>
                <Label>Проведено</Label>
                <Checkbox
                  onChange={(e) => setCarriedOut(e.target.checked)}
                  checked={carriedOut}
                >
                  Проведено
                </Checkbox>
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                Документов: {corrOrdersCount} на сумму{" "}
                {priceFormat(parseFloat(corrOrdersSumm))}
              </Col>
              <Col md={2}>
                <Button onClick={downloadCorrOrdersToExcel}>
                  Скачать Excel
                </Button>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md="2">
              <Link
                to={`/sales/corrOrders/new`}
                color="primary"
                className="button-wide btn btn-primary"
              >
                Добавить
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <CorrOrdersTable corrOrders={corrOrders} />

        <Card>
          <CardBody>
            <Pagination
              totalCount={corrOrdersCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
