import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useLocation } from "react-router-dom";

import {
  clarificationBrand,
  estimateFileUpload,
  getEstimateFromFile,
  cartFileUpload,
  searchPrice,
  updatePriceCartByCustomer,
} from "services/api";
import { tableBrandColumns } from "./tableData";
import { OrderForm } from "features/Dashboard/OrderForm";
import { CartTable } from "features/Cart/CartTable";
import useQuery from "utils/useQuery";
import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import { useCart } from "features/Cart/CartContext";
import { CreateProductModal } from "features/Dashboard/CreateProductModal";
import { MySpinner } from "components/MySpinner";
import { HeaderPage } from "components/HeaderPage";
import { SearchResultTable } from "features/Dashboard/SearchResultTable";
import { DashboardToolbar } from "features/Dashboard/DashboardToolbar";
import { EstimateTable } from "features/Dashboard/EstimateTable";

import "./dashboard.scss";
import { NomenclatureModal } from "features/Dashboard/NomenclatureModal";

export const PROVIDERS = [
  "",
  "omega",
  "corona",
  "armtek",
  "trackmotors",
  "shate",
  "favorit",
  "optipart",
  "falogistic",
];

export const DashboardPage = ({ title }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const inputEstimateFile = useRef();
  const inputOrderFile = useRef(null);
  const [result, setResult] = useState([]);
  const [analogs, setAnalogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [headerLoading, setHeaderLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [brands, setBrands] = useState([]);
  const [article, setArticle] = useState(query.get("article") || "");
  const [brand, setBrand] = useState(query.get("brand") || "");
  const [estimateFromFile, setEstimateFromFile] = useState([]);
  const [estimateFile, setEstimateFile] = useState(null);
  const [orderFile, setOrderFile] = useState(null);
  const [fileName, setFileName] = useState(query.get("file") || "");
  const [customer, setCustomer] = useState(
    JSON.parse(localStorage.getItem("customer")) || {}
  );
  const [orderSuccess, setOrderSuccess] = useState(false);
  const { setCart, cart } = useCart();
  const [orderUploadLoading, setOrderUploadLoading] = useState(false);
  const [openNomenclatureModal, setOpenNomenclatureModal] = useState(false);
  const [id, setId] = useState(null);

  const scrollRef = useRef(null);
  const selectedRef = useRef(null);

  const sortResult = (items) => {
    return items.sort(function (a, b) {
      if (a.sort > b.sort) {
        return 1;
      }
      if (a.sort < b.sort) {
        return -1;
      }
      return 0;
    });
  };

  const searchProviders = (article, brand) => {
    if (loading) return;
    setResult([]);
    setAnalogs([]);
    setLoading(true);
    setProgress(0);
    const percent = 100 / PROVIDERS.length;

    PROVIDERS.forEach((provider, i) => {
      searchPrice({ article, brand, provider, customer: customer.value })
        .then((res) => {
          setResult((prev) => sortResult([...prev, ...res.prices]));
          setAnalogs((prev) => sortResult([...prev, ...res.analogs]));
        })
        .finally(() => {
          setBrands([]);
          setProgress((prev) => Math.floor(prev + percent));
        });
    });
  };

  const getAndSetPrice = (article, brand) => {
    if (loading) return;
    setBrands([]);
    if (!article) return;

    if (!brand) {
      setHeaderLoading(true);
      clarificationBrand(article)
        .then((res) => {
          if (res.length <= 1) {
            if (res[0]) {
              setBrand(res[0].brand?.name);
              return;
            }
            searchProviders(article, "");
          } else {
            setBrands(res);
          }
        })
        .catch((e) => console.error(e))
        .finally(() => setHeaderLoading(false));

      return;
    }
    searchProviders(article, brand);
  };

  useEffect(() => {
    if (progress >= 99) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [progress]);

  useEffect(() => {
    if (!orderSuccess) return;
    setBrands([]);
    setArticle("");
    setBrand("");
  }, [orderSuccess]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (fileName) {
      url.append("file", fileName);
    }
    if (article) {
      url.append("article", article);
    }
    if (brand) {
      url.append("brand", brand);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    if (article.length < 2) {
      return;
    }
    getAndSetPrice(article, brand);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName, article, brand]);

  useEffect(() => {
    if (!customer) return;

    localStorage.setItem("customer", JSON.stringify(customer));

    if (!cart.length) return;

    updatePriceCartByCustomer({ customer: customer.value })
      .then((res) => {
        setCart(res.cart);
      })
      .finally(() => {});
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const estimateHandler = ({ article, brand }) => {
    if (!loading) {
      setArticle(article);
      setBrand(brand);
    }
  };

  const brandHandler = {
    onClick: (_, row) => {
      if (!loading) {
        setArticle(row.article);
        setBrand(row.brand?.name);
      }
    },
  };

  const getEstimateFile = () => {
    if (inputEstimateFile) {
      inputEstimateFile.current.click();
    }
  };

  const getOrderFile = () => {
    if (inputOrderFile) {
      inputOrderFile.current.click();
    }
  };

  useEffect(() => {
    if (!fileName) return;
    getEstimateFromFile({ fileName })
      .then((res) => {
        setEstimateFromFile(res);
        if (scrollRef.current && selectedRef.current) {
          scrollRef.current.scrollTop = selectedRef.current.offsetTop - 40;
        }
      })
      .finally();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!estimateFile) return;
    const formData = new FormData();
    formData.append("file", estimateFile);
    estimateFileUpload(formData)
      .then((res) => {
        setEstimateFromFile(res.items);
        setFileName(res.file);
      })
      .finally();
  }, [estimateFile]);

  useEffect(() => {
    if (!orderFile) return;
    setOrderUploadLoading(true);
    const formData = new FormData();
    formData.append("file", orderFile);
    cartFileUpload(formData)
      .then((res) => {
        setCart(res.cart);
        setCustomer({ label: res.customer?.name, value: res.customer?.id });
        localStorage.setItem(
          "customer",
          JSON.stringify({
            label: res.customer?.name,
            value: res.customer?.value,
          })
        );
      })
      .finally(() => {
        setOrderUploadLoading(false);
        inputOrderFile.current.value = "";
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFile]);

  return (
    <Container fluid className="p-0 dashboard">
      <HeaderPage title="Поиск детали" Element={CreateProductModal} />

      <Card className="mb-0">
        <CardBody className="pb-0">
          {headerLoading && <MySpinner overlay />}
          <Row>
            <Col lg="5">
              <form>
                <div>
                  <Label>Поиск запчастей</Label>
                  <AutocompleteArticle
                    searchButton={true}
                    article={article}
                    brand={brand}
                    setArticle={setArticle}
                    setBrand={({ id, name }) => setBrand(name)}
                    getAndSetPrice={getAndSetPrice}
                    size
                  />
                  {brand && <h5 className="mt-2">{brand}</h5>}
                  {/* 
                  {loading && (
                    <Button onClick={stopSearch}>Остановить поиск</Button>
                  )} */}

                  {/* <div className="providers-list">
                    {providers.map((item) => (
                      <Badge key={item} color="primary" className="mr-1 mb-1">
                        {item}
                      </Badge>
                    ))}
                  </div> */}
                </div>
              </form>
            </Col>
            <Col lg="7" className="dashboard__buttons-block flex">
              <DashboardToolbar
                fileName={fileName}
                setFileName={setFileName}
                getEstimateFile={getEstimateFile}
                setEstimateFile={setEstimateFile}
                inputEstimateFile={inputEstimateFile}
                getOrderFile={getOrderFile}
                inputOrderFile={inputOrderFile}
                setOrderFile={setOrderFile}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              {brands.length > 0 && (
                <BootstrapTable
                  rowEvents={brandHandler}
                  keyField="brand"
                  data={brands}
                  hover={true}
                  columns={tableBrandColumns}
                  bootstrap4
                  bordered={false}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div className="dashboard__search-result">
        {fileName && (
          <Card className="dashboard__search-left">
            <CardBody>
              <h4 className="mb-4">Проценка из файла</h4>

              <EstimateTable
                estimateFromFile={estimateFromFile}
                selectedRef={selectedRef}
                article={article}
                estimateHandler={estimateHandler}
                scrollRef={scrollRef}
              />
            </CardBody>
          </Card>
        )}
        <Row className="dashboard__search-right">
          <Col lg="12">
            <div className="results">
              <Card className="mb-0">
                <CardBody className="pb-1">
                  <h3>Результаты поиска</h3>
                  {loading && (
                    <div className="d-flex justify-content-between">
                      <Progress animated value={progress} className="mb-3">
                        {progress} %
                      </Progress>
                      {/* <Button color="danger" onClick={() => stopSearch()}>
                  <FontAwesomeIcon icon={faWindowClose} />
                </Button> */}
                    </div>
                  )}

                  {progress > 98 && !result.length && !analogs.length && (
                    <h4>Ничего не найдено</h4>
                  )}
                </CardBody>
              </Card>
              <SearchResultTable
                result={result}
                setId={setId}
                setOpenNomenclatureModal={setOpenNomenclatureModal}
              />

              {analogs.length > 0 && (
                <>
                  <Card className="mb-0">
                    <CardBody className="pb-1">
                      <h3>Аналоги</h3>
                    </CardBody>
                  </Card>
                  <SearchResultTable
                    result={analogs}
                    setId={setId}
                    setOpenNomenclatureModal={setOpenNomenclatureModal}
                  />
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <CartTable
        orderSuccess={orderSuccess}
        customer={customer}
        setCustomer={setCustomer}
      />

      <OrderForm
        customer={customer}
        setCustomer={setCustomer}
        getAndSetPrice={getAndSetPrice}
        setOrderSuccess={setOrderSuccess}
      />

      <NomenclatureModal
        openModal={openNomenclatureModal}
        setOpenModal={setOpenNomenclatureModal}
        id={id}
      />
    </Container>
  );
};
