import React, { useEffect, useRef, useState } from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { priceFormat } from "utils/format";
import Select from "react-select";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import { CornerRightDown as CornerRightDownIcon } from "react-feather";
import classNames from "classnames";
import { Trash2, Edit } from "react-feather";
import { toast } from "react-toastify";

import { MySpinner } from "components/MySpinner";
import { AddProductModal } from "../AddProductModal";
import { TableButton } from "components/TableButton";
import { BarChart } from "components/BarChart";
import {
  removeSupplyProducts,
  supplyFileImportUpdate,
  supplyFileImportUpload,
  updateSupplyProduct,
} from "services/apiSupply";

import "./ordersToProviderProductTable.scss";

export const OrdersToProviderProductTable = ({
  id,
  products,
  setProducts,
  carriedOut,
  setCarriedOut,
  bailor,
  provider,
  warehouses,
}) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const inputSupplyFile = useRef(null);
  const [supplyFile, setSupplyFile] = useState(null);
  const [template, setTemplate] = useState(null);
  const [product, setProduct] = useState(null);
  const [warehouse, setWarehouse] = useState(null);

  const removeProductsHandler = (id = null) => {
    if (!window.confirm("Вы действительно хотите удалить выбранные товары?")) {
      return;
    }

    setLoading(true);

    removeSupplyProducts({ ids: id ? [id] : selected })
      .then((res) => {
        setProducts(res.products);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        setSelected([]);
      });
  };

  useEffect(() => {
    if (!supplyFile) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", supplyFile);
    formData.append("id", id || null);
    formData.append("template", template.value);
    supplyFileImportUpload(formData)
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => {
        setLoading(false);
        inputSupplyFile.current.value = "";
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyFile]);

  const productHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);

    updateSupplyProduct({
      ...row,
    })
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => setLoading(false));
  };

  const selectRow = {
    mode: "checkbox",
    bgColor: "#bfbfbf",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelected((prev) => {
        if (!isSelect) {
          prev.splice(
            prev.findIndex((val) => val === row.id),
            1
          );
          return [...prev];
        }
        return [...prev, row.id];
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setSelected([]);
      } else {
        setSelected(products.map((val) => val.id));
      }
    },
  };

  const importHandler = (val) => {
    setTemplate(val);

    if (inputSupplyFile) {
      inputSupplyFile.current.click();
    }
  };

  const changeWarehouseHandler = () => {};

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
      clickToSelect: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <span className="cute">{row.nomenclature?.name}</span>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => classNames("right bold"),
      footer: () => null,
      editable: false,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => classNames("right", { editable: !carriedOut }),
      footer: (columnData) => {
        const total = columnData.reduce((acc, item) => acc + parseInt(item), 0);
        return total;
      },
      editable: false,
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      footerClasses: () => "right bold",
      classes: () => "right bold",
      editable: false,
      footer: (columnData) => {
        const total = columnData.reduce((acc, item) => acc + item, 0);
        return total && priceFormat(total);
      },
    },
    {
      dataField: "",
      text: "ред.",
      formatter: (cell, row) => (
        <TableButton
          Icon={Trash2}
          onClick={() => removeProductsHandler(row.id)}
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      footer: () => null,
      editable: false,
    },
  ];

  const rowStyle = (row, rowIndex) => {
    const style = {};

    if (!row.nomenclature?.id) {
      style.backgroundColor = "#FF5C5C";
    }

    return style;
  };

  const rowClasses = (row, rowIndex) => {
    const foundCarriedOut = products.find((item) => item.carriedOut);

    if (foundCarriedOut && !row.carriedOut) {
      return "error";
    }
  };

  return (
    <>
      {loading && <MySpinner overlay />}

      <Card className="order-to-provider-product mb-0">
        <CardBody>
          {loading && <MySpinner overlay />}

          <Row className="order-to-provider-product__toolbar">
            <Col md="12" className="button-block">
              <Button
                color="primary"
                onClick={() => setOpenModal(true)}
                className="button-wide"
              >
                Добавить товар
              </Button>

              {selected.length > 0 && (
                <div className="d-flex">
                  <Button
                    onClick={() => removeProductsHandler()}
                    color="danger"
                  >
                    <Trash2 size="15" /> Удалить
                  </Button>
                </div>
              )}

              <>
                <Button onCLick={importHandler}>Импорт</Button>
                <Input
                  type="file"
                  id="supply"
                  hidden
                  innerRef={inputSupplyFile}
                  onChange={(e) => setSupplyFile(e.target.files[0])}
                />

                <a
                  href="https://api.lider-truck.ru/files/templates/supplyTemplate.xls"
                  className="btn btn-secondary button-wide"
                >
                  Скачать шаблон
                  <CornerRightDownIcon size={15} />
                </a>
              </>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <BootstrapTable
        wrapperClasses="orders-to-provider-product"
        keyField="id"
        data={products}
        hover={true}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            productHandler(oldData, newData, row),
        })}
        selectRow={selectRow}
        rowStyle={rowStyle}
        rowClasses={rowClasses}
      />

      <AddProductModal
        orderToProviderId={id}
        product={product}
        setProduct={setProduct}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setProducts={setProducts}
        setLoading={setLoading}
        provider={provider}
      />
    </>
  );
};
