export const validHandler = (fields, validation = []) => {
  let valid = true;
  let errors = [];

  for (const field of Object.entries(fields)) {
    const name = field[0];
    const value = field[1];

    if (validation.length > 1) {
      if (validation.indexOf(name) !== -1) {
        if (value === "" || !value) {
          errors[name] = "Поле обязательно для заполнения";
          valid = false;
        }
      }
    } else {
      if (value === "" || !value) {
        errors[name] = "Поле обязательно для заполнения";
        valid = false;
      }
    }
  }

  //Validation email
  if (fields.email) {
    const reEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!reEmail.test(fields.email)) {
      errors["email"] = "Email должен быть вида email@example.com";
      valid = false;
    }
  }

  return { valid, errors };
};
