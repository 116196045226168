export const dateFormat = (date) => {
  if (!date) return "";
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(date).toLocaleDateString("ru-RU", options);
};

export const dateFormatU = (date) => {
  if (!date) return "";

  const dateObject = new Date(date);
  let day = dateObject.getDate();
  if (String(day).length < 2) {
    day = `0${day}`;
  }
  let month = dateObject.getMonth() + 1;
  if (String(month).length < 2) {
    month = `0${month}`;
  }
  const year = dateObject.getFullYear();
  return `${year}-${month}-${day}`;
};

export const dateTimeFormat = (date) => {
  if (!date) return "";
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return new Date(date).toLocaleDateString("ru-RU", options);
};

export const timeFormat = (date) => {
  if (!date) return "";
  const options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
  return new Date(date).toLocaleTimeString("ru-RU", options);
};

export const dateFormatTimestmp = (timestamp) => {
  if (!timestamp) return "";
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(timestamp * 1000).toLocaleDateString("ru-RU", options);
};

export const priceFormat = (price) => {
  if (!price) return 0;
  return parseFloat(price).toLocaleString("ru-RU");
};
