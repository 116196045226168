import React, { useState, useEffect } from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import classNames from "classnames";

import { getNomenclature, updateNomenclature } from "services/api";
import { dateFormat, priceFormat } from "utils/format";
import { GetBestPrice } from "../GetBestPrice";
import { searchPrice } from "services/api";
import { PROVIDERS } from "pages/dashboard";
import {
  getNomenclatureInReserve,
  updateBestPrice,
} from "services/apiAnalitics";
import { GetReserveModal } from "features/AnaliticsStock/GetReserveModal";

import "./analiticsStock.scss";

export const AnaliticsStockTable = ({
  analiticsStock,
  setLoading,
  getAndSetAnaliticsStock,
  onSort,
}) => {
  const [result, setResult] = useState([]);
  const [progress, setProgress] = useState(0);
  const [analiticsId, setAnaliticstId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [nomenclatureId, setNomenclatureId] = useState(null);
  const [reservedOrders, setReservedOrders] = useState([]);
  const [reserveModalLoading, setReserveModalLoading] = useState([]);

  const sortResult = (items) => {
    return items.sort(function (a, b) {
      if (a.sort > b.sort) {
        return 1;
      }
      if (a.sort < b.sort) {
        return -1;
      }
      return 0;
    });
  };

  const getBestPriceHandler = (article, brand, id) => {
    setLoading(true);
    setAnaliticstId(id);
    setProgress(0);
    setResult([]);
    const percent = 100 / PROVIDERS.length;
    PROVIDERS.forEach((provider, i) => {
      searchPrice({ article, brand, provider })
        .then((res) => {
          setResult((prev) =>
            sortResult([...prev, ...res.prices]).filter(
              (r) => r.providerId !== "518680fd-6d10-4543-96a4-108534ba69ca"
            )
          );
        })
        .finally(() => {
          setProgress((prev) => Math.floor(prev + percent));
        });
    });
  };

  useEffect(() => {
    if (progress >= 99) {
      if (result.length > 0) {
        console.log(result[0].pricePurchase);
        updateBestPrice({
          id: analiticsId,
          price: result[0].pricePurchase || null,
        })
          .then(() => {
            getAndSetAnaliticsStock();
            toast.success("Цена успешно сохранена");
          })
          .finally(() => {});
      } else {
        toast.error("Ничего не найдено");
        setLoading(false);
      }
    }
  }, [progress]);

  const reserveModalHandler = (nomenclatureId) => {
    setNomenclatureId(nomenclatureId);
    setOpenModal(true);
    setReserveModalLoading(true);
    getNomenclatureInReserve({ id: nomenclatureId })
      .then((res) => {
        setReservedOrders(res);
      })
      .finally(() => {
        setReserveModalLoading(false);
      });
  };

  const tableColumns = [
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "name",
      text: "Наименование",
      headerStyle: () => {
        return { width: "18%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
      editable: false,
    },
    {
      dataField: "costPrice",
      text: "Себ-ть",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
    },
    {
      dataField: "marketplacePrice",
      text: "Площадки",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell, row) =>
        classNames("right bold", { "red-bg": row.marketplacePrice < row.costPrice }),
    },
    {
      dataField: "fixPrice",
      text: "Фикс прайс",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell, row) =>
        classNames("right bold", { "red-bg": row.fixPrice < row.costPrice }),
    },
    {
      dataField: "bestPrice",
      text: "Лучший прайс",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => (
        <>
          {priceFormat(cell)}
          <GetBestPrice
            getBestPriceHandler={() =>
              getBestPriceHandler(row.article, row.brand, row.id)
            }
          />
        </>
      ),
      headerClasses: () => "right",
      classes: () => "center",
      editable: false,
    },
    {
      dataField: "providerName",
      text: "Поставщик",
      headerStyle: () => {
        return { width: "12%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
      editable: false,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "existReserve",
      text: "Резерв",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "exist",
      text: "Своб. ост.",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "warehouseName",
      text: "Склад",
      headerStyle: () => {
        return { width: "9%" };
      },
      editable: false,
    },
    {
      dataField: "daysFromSupply",
      text: "Дней с пост.",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "daysFromShipping",
      text: "Дней с реализ.",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
    },
    {
      dataField: "daysFromReserve",
      text: "Дней с перв. рез.",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) =>
        cell ? (
          <span
            className="link"
            onClick={() => reserveModalHandler(row.nomenclature)}
          >
            {cell}
          </span>
        ) : (
          ""
        ),
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "admin",
      text: "Мен-р",
      headerStyle: () => {
        return { width: "6%" };
      },
      editable: false,
    },
    {
      dataField: "editBy",
      text: "Кто изменил цену",
      headerStyle: () => {
        return { width: "15%" };
      },
      editable: false,
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
  ];

  const editHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateNomenclature({
      id: row.nomenclature,
      name: row.name,
      brand: row.brand,
      article: row.article,
      fixPrice: row.fixPrice,
      minPrice: row.minPrice,
      marketplacePrice: row.marketplacePrice,
    })
      .then((res) => {
        toast.success("Данные успешно сохранены");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={analiticsStock}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            editHandler(oldData, newData, row),
        })}
      />
      <GetReserveModal
        id={nomenclatureId}
        openModal={openModal}
        setOpenModal={setOpenModal}
        reservedOrders={reservedOrders}
        loading={reserveModalLoading}
      />
    </>
  );
};
