import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { sendInvoice } from "services/api";

export const SendInvoiceModal = ({ id, defaultEmail }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState(defaultEmail);
  const [open, setOpen] = useState(false);

  const invoiceSendHandler = () => {
    setLoading(true);
    sendInvoice({ id, email })
      .then((res) => {
        setSuccess(true);
      })
      .finally(() => {
        setLoading(false);
        //  setOpenModal(false);
      });
  };

  useEffect(() => {
    setSuccess(false);
  }, [open]);

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} className="button-wide">
        Отправить счет
      </Button>

      <Modal isOpen={open} toggle={() => setOpen(false)}>
        <ModalHeader>
          <div className="modal-title-text">Отправить счет на email</div>
          <CloseButton onClick={() => setOpen(false)} />
        </ModalHeader>
        <ModalBody className="m-3">
          {loading && <MySpinner overlay />}

          {success ? (
            <h4>Счет успешно отправлен!</h4>
          ) : (
            <>
              <h4>Счет будет отправлен на Email {email}</h4>
              <FormGroup>
                <Input
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </FormGroup>
            </>
          )}

          <div className="d-flex justify-content-between">
            <Button onClick={() => setOpen(false)} color="secondary">
              Закрыть
            </Button>
            <Button onClick={invoiceSendHandler} color="primary">
              Отправить
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
