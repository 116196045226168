import React from "react";
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";

import InputMask from "react-input-mask";
import classNames from "classnames";
import Select from "react-select";

import { MARKUPS } from "pages/userOne/markups";
import "./mainForm.scss";

export const TYPES = [
  { value: 1, label: "Физ." },
  { value: 2, label: "Юр." },
];

export const MainForm = ({
  user,
  changeHandler,
  selectHandler,
  admins,
  errors,
  needValid,
}) => {
  return (
    <Card
      className={classNames("main-form", "mb-0", {
        "main-form__border": user.type === 2,
      })}
    >
      <CardBody>
        {!user.id && (
          <Row>
            <Col className="elem-field">
              <Label className="text-sm-right">Тип</Label>
              <Select
                name="type"
                placeholder="Выберите тип клиента"
                className="react-select-container"
                classNamePrefix="react-select"
                options={TYPES}
                value={TYPES.find((type) => user.type === type.value)}
                onChange={selectHandler}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Фамилия</Label>
            <Input
              name="lastName"
              placeholder="Фамилия"
              value={user.lastName || ""}
              onChange={changeHandler}
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">Имя</Label>
            <Input
              name="firstName"
              placeholder="Имя"
              value={user.firstName || ""}
              onChange={changeHandler}
              invalid={needValid && errors.hasOwnProperty("firstName")}
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">Отчество</Label>
            <Input
              name="middleName"
              placeholder="Отчество"
              value={user.middleName || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Телефон</Label>
            <InputMask
              id="phone"
              name="phone"
              type="tel"
              placeholder="Телефон"
              onChange={changeHandler}
              value={user.phone || ""}
              mask={"+7(999) 999-99-99"}
              className="form-control"
              invalid={needValid && errors.hasOwnProperty("phone")}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">Email</Label>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={user.email || ""}
              onChange={changeHandler}
              invalid={needValid && errors.hasOwnProperty("email")}
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Лимит кредита</Label>
            <Input
              name="creditSumMax"
              placeholder="Лимит кредита"
              value={user.creditSumMax || ""}
              onChange={changeHandler}
              invalid={needValid && errors.hasOwnProperty("creditSumMax")}
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">Дней отсрочки</Label>
            <Input
              name="delayDayCount"
              placeholder="Дней отсрочки"
              value={user.delayDayCount || ""}
              onChange={changeHandler}
              invalid={needValid && errors.hasOwnProperty("delayDayCount")}
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Ответственный менеджер</Label>
            <Select
              name="admin"
              placeholder="Ответственный менеджер"
              className="react-select-container"
              classNamePrefix="react-select"
              options={admins}
              value={admins.find((val) => user.admin === val.value)}
              onChange={selectHandler}
              invalid={needValid && errors.hasOwnProperty("admin")}
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">Категория наценки</Label>
            <Select
              name="markup"
              placeholder="Категория наценки"
              className="react-select-container"
              classNamePrefix="react-select"
              options={MARKUPS}
              value={MARKUPS.find((val) => user.markup === val.value)}
              onChange={selectHandler}
              invalid={needValid && errors.hasOwnProperty("markup")}
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Комментарий</Label>
            <Input
              type="textarea"
              name="comment"
              placeholder="Комментарий"
              value={user.comment || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
