import { dateFormat } from "utils/format";

export const tableColumns = [
  {
    dataField: "name",
    text: "Имя",
    headerStyle: (colum, colIndex) => {
      return { width: '12%' };
    }
  },
  {
    dataField: "active",
    text: "Активен",
    headerStyle: (colum, colIndex) => {
      return { width: '4%' };
    },
    formatter: cell => cell ? 'Да' : 'Нет',
  },
  {
    dataField: "orgInn",
    text: "ИНН",
    headerStyle: (colum, colIndex) => {
      return { width: '8%' };
    }
  },
  {
    dataField: "deliveryTime",
    text: "Срок поставки",
    headerStyle: (colum, colIndex) => {
      return { width: '3%' };
    }
  },
  {
    dataField: "probability",
    text: "Вер-сть поставки",
    headerStyle: (colum, colIndex) => {
      return { width: '3%' };
    }
  },
  {
    dataField: "countPrices",
    text: "Кол-во в прайсе",
    headerStyle: (colum, colIndex) => {
      return { width: '3%' };
    }
  },
  {
    dataField: "priceLog",
    text: "Прайс лог",
    headerStyle: (colum, colIndex) => {
      return { width: '7%' };
    }
  },
  {
    dataField: "priceLastUpdate",
    text: "Последнее обновление прайса",
    headerStyle: (colum, colIndex) => {
      return { width: '8%' };
    },
    formatter: cell => cell ? dateFormat(cell) : '',
  },
];