import React from "react";
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";

import { FormTitle } from "components/FormTitle";

import "./legalForm.scss";

export const LegalForm = ({ user, changeHandler }) => {
  return (
    <Card className="legal-form mb-0">
      <CardBody>
        <FormTitle>Организация</FormTitle>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Полное название организации</Label>
            <Input
              name="orgName"
              placeholder="Полное название организации"
              value={user.orgName || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Краткое название организации
            </Label>
            <Input
              name="orgNameShort"
              placeholder="Краткое название организации"
              value={user.orgNameShort || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Юридический адрес</Label>
            <Input
              name="orgAddress"
              placeholder="Юридический адрес"
              value={user.orgAddress || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Адрес грузополучателя</Label>
            <Input
              name="consigneeAddress"
              placeholder="Адрес грузополучателя"
              value={user.consigneeAddress || ""}
              onChange={changeHandler}
            />
            <span className="sub">
              Если не заполнено - будет использоваться Юридический адрес в
              документах
            </span>
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">ИНН</Label>
            <Input
              name="orgInn"
              placeholder="ИНН"
              value={user.orgInn || ""}
              onChange={changeHandler}
            />
          </Col>

          <Col className="elem-field">
            <Label className="text-sm-right">КПП</Label>
            <Input
              name="orgKpp"
              placeholder="КПП"
              value={user.orgKpp || ""}
              onChange={changeHandler}
            />
          </Col>

          <Col className="elem-field">
            <Label className="text-sm-right">ОГРН</Label>
            <Input
              name="orgOgrn"
              placeholder="ОГРН"
              value={user.orgOgrn || ""}
              onChange={changeHandler}
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">ОКПО</Label>
            <Input
              name="orgOkpo"
              placeholder="ОКПО"
              value={user.orgOkpo || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Рассчетный счет</Label>
            <Input
              name="orgRs"
              placeholder="Рассчетный счет"
              value={user.orgRs || ""}
              onChange={changeHandler}
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">Корр счет</Label>
            <Input
              name="orgKs"
              placeholder="Корр счет"
              value={user.orgKs || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">БИК</Label>
            <Input
              name="orgBik"
              placeholder="Рассчетный счет"
              value={user.orgBik || ""}
              onChange={changeHandler}
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">Банк</Label>
            <Input
              name="orgBank"
              placeholder="Корр счет"
              value={user.orgBank || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">ФИО генерального директора</Label>
            <Input
              name="genDir"
              placeholder="ФИО генерального директора"
              value={user.genDir || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              ФИО генерального директора в род. падеже
            </Label>
            <Input
              name="genDirGenitive"
              placeholder="ФИО генерального директора в родительном падеже"
              value={user.genDirGenitive || ""}
              onChange={changeHandler}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
