import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { dateFormat, timeFormat } from "utils/format";

import "./changeLog.scss";

export const ChangeLogModal = ({ open, setOpen, changeLog, loading }) => {
  const dateRow = (item, i) => {
    if (i > 0) {
      if (
        dateFormat(changeLog[i]["createdAt"]) ===
        dateFormat(changeLog[i - 1]["createdAt"])
      ) {
        return;
      }
    }

    return (
      <tr>
        <td colSpan={4} className="center">
          <strong>{dateFormat(item.createdAt)}</strong>
        </td>
      </tr>
    );
  };

  const fieldTextRender = (item) => {
    if (item.action === "Обновление") {
      return (
        <>
          {item.fieldText}: {item.prevValue || `' '`} →{" "}
          {item.postValue || `' '`}
        </>
      );
    }

    return (
      <>
        {item.entityText} {item.prevValue}
        {item.postValue}
      </>
    );
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} className="history-button">
        История
      </Button>
      <Modal
        isOpen={open}
        toggle={() => setOpen(false)}
        className="modalChangeLog"
      >
        <ModalHeader>
          <div className="modal-title-text">История изменений</div>
          <CloseButton onClick={() => setOpen(false)} />
        </ModalHeader>
        <ModalBody className="m-3">
          {loading && <MySpinner overlay />}
          <div className="changeLog">
            <table class="my-2 table table-sm">
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Действие</th>
                  <th>Изменение</th>
                  <th>Кто изменил</th>
                </tr>
              </thead>
              <tbody>
                {changeLog.map((item, i) => (
                  <>
                    {dateRow(item, i)}

                    <tr key={item.id}>
                      <td>{timeFormat(item.createdAt)}</td>
                      <td>{item.action}</td>
                      <td>{fieldTextRender(item)}</td>
                      <td>
                        {item.admin}
                        {item.customer}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
