import axios from "axios";
export const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_DEV_URL;

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: { "Content-Type": "application/json" },
});

export const controller = new AbortController();

axiosInstance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("accessToken") != null) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "accessToken"
      )}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  function (config) {
    return config;
  },
  async (e) => {
    const originalRequest = e.config;
    if (e.response?.status === 401 && e.config && !originalRequest._isRetry) {
      originalRequest._isRetry = true;
      try {
        const data = new URLSearchParams();
        data.append("grantType", "refreshToken");
        data.append("refreshToken", localStorage.getItem("refreshToken"));
        const res = await axios.post(`${baseUrl}/requestAdminToken`, data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        return axiosInstance.request(originalRequest);
      } catch (e) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/sign-in";
      }
    }
    throw e;
  }
);

export const login = async (props) => {
  const data = new URLSearchParams();
  data.append("grantType", "password");
  data.append("username", props.email);
  data.append("password", props.password);

  try {
    const res = await axios.post(`${baseUrl}/requestAdminToken`, data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    return res.data;
  } catch (e) {
    console.error(e.message);
    if (e.message === "Network Error") {
      return "Network Error";
    }
    if (e.response) {
      return e.response.status;
    }
  }
};

export const suggestion = async ({ article }) => {
  const params = {
    article,
  };
  const res = await axiosInstance.get("/price.suggestion", { params });
  return res.data;
};

export const priceMailing = async (props) => {
  const res = await axiosInstance.post("/price.mailingCsv", props);
  return res.data;
};

export const suggestionNomenclatrue = async ({ article }) => {
  const params = {
    article,
  };
  const res = await axiosInstance.get("/nomenclature.suggestion", { params });
  return res.data;
};

export const createNomenclature = async (props) => {
  const res = await axiosInstance.post("/nomenclature.create", props);
  return res.data;
};

export const getNomenclature = async ({
  paginationCount,
  paginationOffset,
  search,
  sort,
  withPrice,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }

  if (withPrice) {
    params.withPrice = withPrice;
  }

  if (sort) {
    params["sort[by]"] = sort.by;
    params["sort[dir]"] = sort.dir;
  }

  const res = await axiosInstance.get("/nomenclature.get", { params });
  return res.data;
};

export const getNomenclatureOne = async (id) => {
  const params = { id };
  const res = await axiosInstance.get("/nomenclature.getOne", { params });
  return res.data;
};

export const nomenclatureAddImages = async (props) => {
  const res = await axiosInstance.post("/nomenclature.addImages", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const nomenclatureRemoveImages = async (props) => {
  const res = await axiosInstance.post("/nomenclature.removeImages", props);
  return res.data;
};

export const updateNomenclature = async (props) => {
  const res = await axiosInstance.post("/nomenclature.update", props);
  return res.data;
};

export const getCharacteristics = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/characteristics.get", { params });
  return res.data;
};

export const getCharacteristicsList = async () => {
  const res = await axiosInstance.get("/characteristics.getList");
  return res.data;
};

export const createCharacteristic = async (props) => {
  const res = await axiosInstance.post("/characteristics.create", props);
  return res.data;
};

export const removeCharacteristic = async (props) => {
  const res = await axiosInstance.post("/characteristics.remove", props);
  return res.data;
};

export const updateCharacteristic = async (props) => {
  const res = await axiosInstance.post("/characteristics.update", props);
  return res.data;
};

export const addCharacteristicValue = async (props) => {
  const res = await axiosInstance.post("/characteristics.addValue", props);
  return res.data;
};

export const updateCharacteristicValue = async (props) => {
  const res = await axiosInstance.post("/characteristics.updateValue", props);
  return res.data;
};

export const removeCharacteristicValue = async (props) => {
  const res = await axiosInstance.post("/characteristics.removeValue", props);
  return res.data;
};

export const searchPrice = async ({ article, brand, provider, customer }) => {
  const params = {
    article,
    brand,
    provider,
    customer,
  };
  const res = await axiosInstance.get("/price.search", {
    signal: controller.signal,
    params,
  });
  return res.data;
};

export const createProduct = async (props) => {
  const res = await axiosInstance.post("/price.createProduct", props);
  return res.data;
};

export const getEstimateFromFile = async ({ fileName }) => {
  const params = {
    fileName,
  };
  const res = await axiosInstance.get("/price.getEstimateFromFile", { params });
  return res.data;
};

export const clarificationBrand = async (article) => {
  const params = {
    article,
  };
  const res = await axiosInstance.get("/price.clarificationBrand", { params });
  return res.data;
};

export const estimateFileUpload = async (props) => {
  const res = await axiosInstance.post("/price.estimateUpload", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const orderFileUpload = async (props) => {
  const res = await axiosInstance.post("/orders.orderUpload", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const cartFileUpload = async (props) => {
  const res = await axiosInstance.post("/cart.orderUpload", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const cartAdd = async (props) => {
  const res = await axiosInstance.post("/cart.add", props);
  return res.data;
};

export const cartUpdate = async (props) => {
  const res = await axiosInstance.post("/cart.update", props);
  return res.data;
};

export const updatePriceCartByCustomer = async (props) => {
  const res = await axiosInstance.post("/cart.updatePriceByCustomer", props);
  return res.data;
};

export const cartClean = async (props) => {
  const res = await axiosInstance.post("/cart.clean", props);
  return res.data;
};

export const cartGet = async () => {
  const res = await axiosInstance.get("/cart.get");
  return res.data;
};

export const createOrder = async (props) => {
  const res = await axiosInstance.post("/orders.create", props);
  return res.data;
};

export const carryOutOrder = async (props) => {
  const res = await axiosInstance.post("/orders.carryOut", props);
  return res.data;
};

export const getReturnsCustomer = async ({
  paginationCount,
  paginationOffset,
  search,
  number,
  provider,
  supply,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
    search,
    number,
    provider,
    supply,
  };

  const res = await axiosInstance.get("/returnsFromCustomer.get", { params });
  return res.data;
};

export const getReturnCustomerOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/returnsFromCustomer.getOne", {
    params,
  });
  return res.data;
};

export const createReturnCustomer = async (props) => {
  const res = await axiosInstance.post("/returnsFromCustomer.create", props);
  return res.data;
};

export const carryOutReturnCustomer = async (props) => {
  const res = await axiosInstance.post("/returnsFromCustomer.carryOut", props);
  return res.data;
};

export const userSuggestion = async (search) => {
  const params = {
    search,
  };
  const res = await axiosInstance.get("/users.suggestion", { params });
  return res.data;
};

export const getAddresses = async (customer) => {
  const params = {
    customer,
  };
  const res = await axiosInstance.get("/addresses.get", { params });
  return res.data;
};

export const addAddress = async (props) => {
  const res = await axiosInstance.post("/addresses.add", props);
  return res.data;
};

export const setDefaultAddress = async (props) => {
  const res = await axiosInstance.post("/addresses.setDefault", props);
  return res.data;
};

export const getDefaultAddress = async (customer) => {
  const params = {
    customer,
  };
  const res = await axiosInstance.get("/addresses.getDefault", { params });
  return res.data;
};

export const removeAddress = async (props) => {
  const res = await axiosInstance.post("/addresses.remove", props);
  return res.data;
};

export const getUsers = async ({
  paginationCount,
  paginationOffset,
  search,
  sort,
  admin,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }

  if (admin) {
    params.admin = admin;
  }

  if (sort) {
    params["sort[by]"] = sort.by;
    params["sort[dir]"] = sort.dir;
  }

  const res = await axiosInstance.get("/users.get", { params });
  return res.data;
};

export const getUser = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/users.getOne", { params });
  return res.data;
};

export const getCurrentUser = async () => {
  const res = await axiosInstance.get("/users.getCurrent");
  return res.data;
};

export const updateUser = async (props) => {
  const res = await axiosInstance.post("/users.update", props);
  return res.data;
};

export const createUser = async (props) => {
  const res = await axiosInstance.post("/users.create", props);
  return res.data;
};

export const innSuggestion = async (search) => {
  const params = {
    search,
  };
  const res = await axiosInstance.get("/users.innSuggestion", { params });
  return res.data;
};

export const bankSuggestion = async (search) => {
  const params = {
    search,
  };
  const res = await axiosInstance.get("/users.bankSuggestion", { params });
  return res.data;
};

export const getContracts = async (customer) => {
  const params = {
    customer,
  };
  const res = await axiosInstance.get("/contracts.get", { params });
  return res.data;
};

export const createContract = async (props) => {
  const res = await axiosInstance.post("/contracts.create", props);
  return res.data;
};

export const removeContract = async (props) => {
  const res = await axiosInstance.post("/contracts.remove", props);
  return res.data;
};

export const setDefaultContract = async (props) => {
  const res = await axiosInstance.post("/contracts.setDefault", props);
  return res.data;
};

export const getDogovor = async (props) => {
  const res = await axiosInstance.post("/users.getDogovor", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getProviders = async ({
  paginationCount,
  paginationOffset,
  search,
  price,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }
  if (price) {
    params.price = price;
  }
  const res = await axiosInstance.get("/providers.get", { params });
  return res.data;
};

export const updateProvider = async (props) => {
  const res = await axiosInstance.post("/providers.update", props);
  return res.data;
};

export const createProvider = async (props) => {
  const res = await axiosInstance.post("/providers.create", props);
  return res.data;
};

export const getAdmins = async () => {
  const res = await axiosInstance.get("/users.getAdminsAll");
  return res.data;
};

export const updatePassword = async (props) => {
  const res = await axiosInstance.post("/users.updatePassword", props);
  return res.data;
};

export const getOrders = async ({
  paginationCount,
  paginationOffset,
  search,
  dateStart,
  dateEnd,
  number,
  number1C,
  customer,
  admin,
  status,
  payment,
  shippingDate,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
    search,
    dateStart,
    dateEnd,
    number,
    number1C,
    customer,
    admin,
    status,
    payment,
    shippingDate,
  };

  const res = await axiosInstance.get("/orders.get", { params });
  return res.data;
};

export const getOrder = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/orders.getOne", { params });
  return res.data;
};

export const getOrderByNumber = async (number) => {
  const params = {
    number,
  };
  const res = await axiosInstance.get("/orders.getOneByNumber", { params });
  return res.data;
};

export const getStatuses = async () => {
  const res = await axiosInstance.get("/orders.getStatuses");
  return res.data;
};

export const setStatus = async (props) => {
  const res = await axiosInstance.post("/orders.setStatus", props);
  return res.data;
};

export const updateOrder = async (props) => {
  const res = await axiosInstance.post("/orders.updateOrder", props);
  return res.data;
};

export const createReserve = async (props) => {
  const res = await axiosInstance.post("/orders.createReserve", props);
  return res.data;
};

export const removeFromReserve = async (props) => {
  const res = await axiosInstance.post("/orders.removeFromReserve", props);
  return res.data;
};

export const removeProduct = async (props) => {
  const res = await axiosInstance.post("/orders.removeProduct", props);
  return res.data;
};

export const removeProducts = async (props) => {
  const res = await axiosInstance.post("/orders.removeProducts", props);
  return res.data;
};

export const addProduct = async (props) => {
  const res = await axiosInstance.post("/orders.addProduct", props);
  return res.data;
};

export const moveProduct = async (props) => {
  const res = await axiosInstance.post("/orders.moveProduct", props);
  return res.data;
};

export const updateProduct = async (props) => {
  const res = await axiosInstance.post("/orders.updateProduct", props);
  return res.data;
};

export const sendToDiadoc = async (props) => {
  const res = await axiosInstance.post("/orders.sendToDiadoc", props);
  return res.data;
};

export const sendTo1S = async (props) => {
  const res = await axiosInstance.post("/orders.sendTo1S", props);
  return res.data;
};

export const getGtd = async (props) => {
  const res = await axiosInstance.post("/orders.getGtd", props);
  return res.data;
};

export const getUpd = async (props) => {
  const res = await axiosInstance.post("/orders.getUpd", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getCheck = async (props) => {
  const res = await axiosInstance.post("/orders.getCheck", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getInvoice = async (props) => {
  const res = await axiosInstance.post("/orders.getInvoice", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getOffer = async (props) => {
  const res = await axiosInstance.post("/orders.getOffer", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getOrdersToExcel = async (props) => {
  const res = await axiosInstance.post("/orders.getToExcel", props, {
    responseType: "blob",
  });
  return res.data;
};

export const sendInvoice = async (props) => {
  const res = await axiosInstance.post("/orders.sendInvoice", props);
  return res.data;
};

export const sendToDiadocFromUpd = async (props) => {
  const res = await axiosInstance.post("/orders.sendToDiadocFromUpd", props);
  return res.data;
};

export const updFileUpload = async (props) => {
  const res = await axiosInstance.post("/orders.updFileUpload", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const addBonus = async (props) => {
  const res = await axiosInstance.post("/orders.setBonus", props);
  return res.data;
};

export const updateComment = async (props) => {
  const res = await axiosInstance.post("/orders.updateComment", props);
  return res.data;
};

export const getReportChangedOrders = async (props) => {
  const res = await axiosInstance.post("/orders.getReportChanged", props, {
    responseType: "blob",
  });
  return res.data;
};

export const changeWarehouse = async (props) => {
  const res = await axiosInstance.post("/orders.productChangeWarehouse", props);
  return res.data;
};

export const getOrderPicking = async (number) => {
  const params = {
    number,
  };
  const res = await axiosInstance.get("/orderPicking.get", { params });
  return res.data;
};

export const endPicking = async (props) => {
  const res = await axiosInstance.post("/orderPicking.end", props);
  return res.data;
};

export const getChangeLogOrder = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/changeLog.getOrder", { params });
  return res.data;
};

export const getChangeLogSupply = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/changeLog.getSupply", { params });
  return res.data;
};

export const updConvertUpload = async (props) => {
  const res = await axiosInstance.post("/updConvert.upload", props);
  return res.data;
};

export const updConvertUpdateData = async (props) => {
  const res = await axiosInstance.post("/updConvert.update", props);
  return res.data;
};

export const updConvertDownload = async (props) => {
  const res = await axiosInstance.post("/updConvert.get", props, {
    headers: { "Content-Type": "multipart/form-data" },
    responseType: "blob",
  });
  return res.data;
};

export const providersSuggestion = async (search) => {
  const params = {
    search,
  };
  const res = await axiosInstance.get("/providers.suggestion", { params });
  return res.data;
};

export const getProvider = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/providers.getOne", { params });
  return res.data;
};

export const getBrands = async ({
  paginationCount,
  paginationOffset,
  search,
  sort,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }

  if (sort) {
    params["sort[by]"] = sort.by;
    params["sort[dir]"] = sort.dir;
  }

  const res = await axiosInstance.get("/brands.get", { params });
  return res.data;
};

export const createBrand = async (props) => {
  const res = await axiosInstance.post("/brands.createBrand", props);
  return res.data;
};

export const updateBrand = async (props) => {
  const res = await axiosInstance.post("/brands.updateBrand", props);
  return res.data;
};

export const setBrand = async (props) => {
  const res = await axiosInstance.post("/nomenclature.setBrand", props);
  return res.data;
};

export const getBrandSynonyms = async ({
  paginationCount,
  paginationOffset,
  search,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }

  const res = await axiosInstance.get("/brands.getSynonyms", { params });
  return res.data;
};

export const updateSynonym = async (props) => {
  const res = await axiosInstance.post("/brands.updateSynonym", props);
  return res.data;
};

export const createSynonym = async (props) => {
  const res = await axiosInstance.post("/brands.createSynonym", props);
  return res.data;
};

export const removeSynonym = async (props) => {
  const res = await axiosInstance.post("/brands.removeSynonym", props);
  return res.data;
};

export const brandsSuggestion = async (search) => {
  const params = {
    search,
  };
  const res = await axiosInstance.get("/brands.suggestion", { params });
  return res.data;
};

export const getPriceRequestLog = async ({
  paginationCount,
  paginationOffset,
  search,
  sort,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }

  if (sort) {
    params.sort = sort;
  }

  const res = await axiosInstance.get("/priceRequestLog.get", { params });
  return res.data;
};

export const getCross = async ({
  paginationCount,
  paginationOffset,
  search,
  sort,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }

  if (sort) {
    params["sort[by]"] = sort.by;
    params["sort[dir]"] = sort.dir;
  }

  const res = await axiosInstance.get("/cross.get", { params });
  return res.data;
};

export const updateCross = async (props) => {
  const res = await axiosInstance.post("/cross.update", props);
  return res.data;
};

export const uploadCross = async (props) => {
  const res = await axiosInstance.post("/cross.uploadFile", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const getCrossFiles = async ({ paginationCount, paginationOffset }) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  const res = await axiosInstance.get("/cross.getFiles", { params });
  return res.data;
};

export const getCrossByArticleBrand = async (props) => {
  const res = await axiosInstance.get("/cross.getByArticleBrand", {
    params: props,
  });
  return res.data;
};

export const removeCrossFile = async (props) => {
  const res = await axiosInstance.post("/cross.removeFile", props);
  return res.data;
};

export const removeCross = async (props) => {
  const res = await axiosInstance.post("/cross.remove", props);
  return res.data;
};

export const createCross = async (props) => {
  const res = await axiosInstance.post("/cross.create", props);
  return res.data;
};
