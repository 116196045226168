import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import { priceFormat } from "utils/format";

import "./returnCustomerProductTable.scss";

export const ReturnCustomerProductTable = ({ products }) => {
  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "provider",
      text: "Поставщик",
      formatter: (cell, row) => <span className="cute">{row.provider}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      classes: () => "bold",
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      classes: () => "bold",
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <span className="cute">{row.nomenclature?.name}</span>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "count",
      text: "Возвр",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      formatter: (cell, row) => priceFormat(cell),
      footer: (columnData) => null,
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      formatter: (cell, row) => priceFormat(cell),
      footer: (columnData) =>
        priceFormat(columnData.reduce((acc, item) => acc + item, 0)),
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "50%" };
      },
      editable: false,
      footer: (columnData) => <></>,
    },
  ];
  return (
    <>
      <BootstrapTable
        wrapperClasses="return-to-provider-product"
        keyField="id"
        data={products}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
      />
    </>
  );
};
