import {
  Users as UsersIcon,
  Package as PackageIcon,
  PlusCircle as PlusIcon,
  Printer as PrinterIcon,
  UploadCloud as DiadocIcon,
  Clipboard as ClipboardIcon,
  LifeBuoy as lifeBuoyIcon,
  List as ListIcon,
  Repeat as repeatIcon,
  Briefcase as BriefcaseIcon,
  BarChart2 as BarChart2Icon,
} from "react-feather";

import { SignIn } from "../pages/SignIn";
import { UsersPage } from "pages/users";
import { UserOnePage } from "pages/userOne";
import { LabelsPage } from "pages/labels";
import { DiadocPage } from "pages/diadoc";
import { OrdersPage } from "pages/orders";
import { OrderOnePage } from "pages/orderOne";
import { ConvertUpdPage } from "pages/convertUpd";
import { ProvidersPage } from "pages/providers";
import { ProviderOnePage } from "pages/providerOne";
import { BrandsPage } from "pages/brands";
import { BrandSynonymsPage } from "pages/brandSynonyms";
import { NomenclaturePage } from "pages/nomenclature";
import { PriceRequestLogPage } from "pages/priceRequestLog";
import { CrossPage } from "pages/cross";
import { CrossUploadPage } from "pages/crossUpload";
import { DashboardPage } from "pages/dashboard";
import { SuppliesPage } from "pages/supplies";
import { SupplyOnePage } from "pages/supplyOne";
import { EnteringBalancePage } from "pages/enteringBalance/EnteringBalance";
import { EnteringBalanceOnePage } from "pages/enteringBalanceOne/EnteringBalanceOne";
import { CorrOrdersPage } from "pages/corrOrders/CorrOrders";
import { CorrOrderOnePage } from "pages/corrOrderOne/CorrOrderOne";
import { ReturnsToProviderPage } from "pages/returnsToProvider/ReturnsToProvider";
import { ReturnToProviderOnePage } from "pages/returnToProviderOne/ReturnToProviderOne";
import { ReturnsCustomerPage } from "pages/returnsCustomer/ReturnsCustomer";
import { ReturnCustomerOnePage } from "pages/returnCustomerOne/ReturnCustomerOne";
import { MovingPage } from "pages/moving/Moving";
import { MovingOnePage } from "pages/movingOne/MovingOne";
import { StockPage } from "pages/stock/Stock";
import { OrdersPickingPage } from "pages/orderPicking";
import { OrdersPickingOnePage } from "pages/orderPickingOne";
import { WriteOffOnePage } from "pages/writeOffOne/WriteOffOne";
import { WriteOffsPage } from "pages/writeOffs/WriteOffs";
import { MisgradingsPage } from "pages/misgradings/Misgradings";
import { MisgradingOnePage } from "pages/misgradingOne/MisgradingOne";
import { AnaliticsPricePage } from "pages/analiticsPrice/AnaliticsPrice";
import { AnaliticsStockPage } from "pages/analiticsStock/AnaliticsStock";
import { SalaryReportPage } from "pages/salaryReport/SalaryReport";
import { AnaliticsSalePage } from "pages/analiticsSale/AnaliticsSale";
import { AnaliticsReceivablesPage } from "pages/analiticsReceivables/AnaliticsReceivables";
import { AcceptanceActsPage } from "pages/acceptanceActs/AcceptanceActs";
import { AcceptanceActOnePage } from "pages/acceptanceActOne/AcceptanceActOne";
import { CharacteristicsPage } from "pages/characteristics";
import { OrdersToProviderPage } from "pages/ordersToProvider";
import { OrderToProviderOnePage } from "pages/orderToProviderOne";

// Routes

const dashboardRoutes = {
  path: "/",
  name: "Оформление заказа",
  title: "Оформление заказа",
  icon: PlusIcon,
  containsHome: true,
  component: DashboardPage,
};

const usersRoutes = {
  path: "/sales/users",
  name: "Клиенты",
  title: "Клиенты",
  icon: UsersIcon,
  component: UsersPage,
};

const userNewRoutes = {
  path: "/sales/users/new",
  name: "Новый клиент",
  title: "Новый клиент",
  component: UserOnePage,
};

const userOneRoutes = {
  path: "/sales/users/:id",
  name: "Клиент",
  component: UserOnePage,
};

const providersRoutes = {
  path: "/receipt/providers",
  name: "Поставщики",
  title: "Поставщики",
  icon: PackageIcon,
  component: ProvidersPage,
};

const providerNewRoutes = {
  path: "/receipt/providers/new",
  name: "Новый Поставщик",
  title: "Новый Поставщик",
  component: ProviderOnePage,
};

const providerOneRoutes = {
  path: "/receipt/providers/:id",
  name: "Поставщик",
  component: ProviderOnePage,
};

const supplyRoutes = {
  path: "/receipt/supply",
  name: "Поступления",
  title: "Поступления",
  icon: PackageIcon,
  component: SuppliesPage,
};

const supplyNewRoutes = {
  path: "/receipt/supply/new",
  name: "Новое поступление",
  title: "Новое поступление",
  component: SupplyOnePage,
};

const supplyOneRoutes = {
  path: "/receipt/supply/:id",
  name: "Поступление",
  component: SupplyOnePage,
};

const enteringBalanceRoutes = {
  path: "/stock/enteringBalance",
  name: "Ввод остатков",
  title: "Ввод остатков",
  icon: PackageIcon,
  component: EnteringBalancePage,
};

const enteringBalanceNewRoutes = {
  path: "/stock/enteringBalance/new",
  name: "Новый ввод остатков",
  title: "Новый ввод остатков",
  component: EnteringBalanceOnePage,
};

const enteringBalanceOneRoutes = {
  path: "/stock/enteringBalance/:id",
  name: "Ввод остатков",
  component: EnteringBalanceOnePage,
};

const misgradingsRoutes = {
  path: "/stock/misgradings",
  name: "Пересортицы",
  title: "Пересортицы",
  icon: PackageIcon,
  component: MisgradingsPage,
};

const misgradingNewRoutes = {
  path: "/stock/misgradings/new",
  name: "Новая пересортица",
  title: "Новая пересортица",
  component: MisgradingOnePage,
};

const misgradingOneRoutes = {
  path: "/stock/misgradings/:id",
  name: "Пересортица",
  component: MisgradingOnePage,
};

const corrOrdersRoutes = {
  path: "/sales/corrOrders",
  name: "Корр. реализаций",
  title: "Корректировки реализаций",
  icon: PackageIcon,
  component: CorrOrdersPage,
};

const corrOrderNewRoutes = {
  path: "/sales/corrOrders/new",
  name: "Новая корректировка реализации",
  title: "Новая корректировка реализации",
  component: CorrOrderOnePage,
};

const corrOrdersOneRoutes = {
  path: "/sales/corrOrders/:id",
  name: "Корректировка реализации",
  component: CorrOrderOnePage,
};

const returnsToProvidersRoutes = {
  path: "/receipt/returnsToProvider",
  name: "Возвраты поставщику",
  title: "Возвраты поставщику",
  icon: PackageIcon,
  component: ReturnsToProviderPage,
};

const returnsToProvidersNewRoutes = {
  path: "/receipt/returnsToProvider/new",
  name: "Новый возврат поставщику",
  title: "Новый возврат поставщику",
  component: ReturnToProviderOnePage,
};

const returnsToProvidersOneRoutes = {
  path: "/receipt/returnsToProvider/:id",
  name: "Возврат поставщику",
  component: ReturnToProviderOnePage,
};

const acceptanceActsRoutes = {
  path: "/receipt/acceptanceActs",
  name: "Акты приема передачи на хранение",
  title: "Акты приема передачи на хранение",
  component: AcceptanceActsPage,
};

const acceptanceActNewRoutes = {
  path: "/receipt/acceptanceActs/new",
  name: "Новый акт приема передачи на хранение",
  component: AcceptanceActOnePage,
};

const acceptanceActOneRoutes = {
  path: "/receipt/acceptanceActs/:id",
  name: "Акт приема передачи на хранение",
  component: AcceptanceActOnePage,
};

const ordersToProviderRoutes = {
  path: "/receipt/ordersToProvider",
  name: "Заказы поставщику",
  title: "Заказы поставщику",
  component: OrdersToProviderPage,
};

const orderToProviderNewRoutes = {
  path: "/receipt/ordersToProvider/new",
  name: "Новый заказ поставщику",
  component: OrderToProviderOnePage,
};

const orderToProviderOneRoutes = {
  path: "/receipt/ordersToProvider/:id",
  name: "Заказ поставщику",
  component: OrderToProviderOnePage,
};

const returnsCustomersRoutes = {
  path: "/sales/returnsFromCustomer",
  name: "Возвраты от клиента",
  title: "Возвраты от клиента",
  icon: PackageIcon,
  component: ReturnsCustomerPage,
};

const returnsCustomerNewRoutes = {
  path: "/sales/returnsFromCustomer/new",
  name: "Новый возврат от клиента",
  title: "Новый возврат от клиента",
  component: ReturnCustomerOnePage,
};

const returnsCustomerOneRoutes = {
  path: "/sales/returnsFromCustomer/:id",
  name: "Возврат от клиента",
  component: ReturnCustomerOnePage,
};

const movingRoutes = {
  path: "/stock/moving",
  name: "Перемещения товаров",
  title: "Перемещения товаров",
  icon: PackageIcon,
  component: MovingPage,
};

const movingNewRoutes = {
  path: "/stock/moving/new",
  name: "Новое перемеение товаров",
  title: "Новое перемещение товаров",
  component: MovingOnePage,
};

const movingOneRoutes = {
  path: "/stock/moving/:id",
  name: "Перемещение товаров",
  component: MovingOnePage,
};

const stockPageRoutes = {
  path: "/stock/stock",
  name: "Движения товаров",
  title: "Движения товаров",
  component: StockPage,
};

const writeOffNewRoutes = {
  path: "/stock/writeOffs/new",
  name: "Новое списание товаров",
  title: "Новое списание товаров",
  component: WriteOffOnePage,
};

const writeOffOneRoutes = {
  path: "/stock/writeOffs/:id",
  name: "Списание товаров",
  component: WriteOffOnePage,
};

const writeOffsPageRoutes = {
  path: "/stock/writeOffs",
  name: "Списания товаров",
  title: "Списания товаров",
  component: WriteOffsPage,
};

const signInRoutes = {
  path: "/sign-in",
  name: "Логин",
  title: "Логин",
  component: SignIn,
};

const labelsRoutes = {
  path: "/labels",
  name: "Печать этикеток",
  title: "Печать этикеток",
  icon: PrinterIcon,
  component: LabelsPage,
};

const diadocRoutes = {
  path: "/diadoc/",
  name: "Выгрузка в Диадок",
  title: "Выгрузка в Диадок",
  icon: DiadocIcon,
  component: DiadocPage,
};

const diadocRoutesByOrder = {
  path: "/diadoc/:id",
  name: "Выгрузка в Диадок",
  icon: DiadocIcon,
  component: DiadocPage,
};

const ordersRoutes = {
  path: "/sales/orders",
  name: "Заказы",
  title: "Заказы",
  icon: ClipboardIcon,
  component: OrdersPage,
};

const orderOneRoutes = {
  path: "/sales/orders/:id",
  name: "Заказ",
  component: OrderOnePage,
};

const convertUpdRoutes = {
  path: "/convertUpd",
  name: "Конвертация УПД",
  title: "Конвертация УПД",
  component: ConvertUpdPage,
};

const brandsRoutes = {
  path: "/reference/brands",
  name: "Бренды",
  title: "Бренды",
  icon: lifeBuoyIcon,
  component: BrandsPage,
};

const brandSynonymsRoutes = {
  path: "/reference/brandSynonyms",
  name: "Синонимы брендов",
  title: "Синонимы брендов",
  icon: lifeBuoyIcon,
  component: BrandSynonymsPage,
};

const nomenclatureRoutes = {
  path: "/reference/nomenclature",
  name: "Номенклатура",
  title: "Номенклатура",
  icon: ListIcon,
  component: NomenclaturePage,
};

const characteristicsRoutes = {
  path: "/reference/characteristics",
  name: "Характеристики",
  title: "Характеристики",
  component: CharacteristicsPage,
};

const priceRequestLogRoutes = {
  path: "/reference/priceRequestLog",
  name: "История запросов",
  title: "История запросов",
  icon: ListIcon,
  component: PriceRequestLogPage,
};

const crossRoutes = {
  path: "/reference/cross",
  name: "Кроссы",
  title: "Кроссы",
  icon: repeatIcon,
  component: CrossPage,
};

const crossRoutesUpload = {
  path: "/reference/cross/upload",
  name: "Загрузки кроссов",
  title: "Загрузки кроссов",
  icon: repeatIcon,
  component: CrossUploadPage,
};

const orderPickingRoute = {
  path: "/stock/orderPicking",
  name: "Заявки на сборку",
  title: "Заявки на сборку",
  icon: repeatIcon,
  component: OrdersPickingPage,
};

const orderPickingOneRoute = {
  path: "/stock/orderPicking/:id",
  name: "Заявка на сборку",
  icon: repeatIcon,
  component: OrdersPickingOnePage,
};

const analiticsPriceRoute = {
  path: "/analitics/analiticsPrice",
  name: "Аналитика цен поставщиков",
  title: "Аналитика цен поставщиков",
  icon: repeatIcon,
  component: AnaliticsPricePage,
};

const analiticsStockRoute = {
  path: "/analitics/analiticsStock",
  name: "Аналитика остатков склада",
  title: "Аналитика остатков склада",
  icon: repeatIcon,
  component: AnaliticsStockPage,
};

const salaryReportRoute = {
  path: "/analitics/salaryReport",
  name: "Отчет по зарплате",
  title: "Отчет по зарплате",
  icon: repeatIcon,
  component: SalaryReportPage,
};

const analiticsSaleRoute = {
  path: "/analitics/analiticsSale",
  name: "Отчет по продажам",
  title: "Отчет по продажам",
  component: AnaliticsSalePage,
};

const analiticsReceivablesRoute = {
  path: "/analitics/analiticsReceivables",
  name: "Аналитика дебеторской задолженности",
  title: "Аналитика дебеторской задолженности",
  component: AnaliticsReceivablesPage,
};

const analiticsRoutes = {
  path: "/analitics",
  name: "Отчеты",
  title: "Отчеты",
  icon: BarChart2Icon,
  children: [
    analiticsPriceRoute,
    analiticsStockRoute,
    salaryReportRoute,
    analiticsSaleRoute,
    analiticsReceivablesRoute,
  ],
};

const salesRoutes = {
  path: "/sales",
  name: "Продажи",
  title: "Продажи",
  icon: ClipboardIcon,
  children: [
    ordersRoutes,
    returnsCustomersRoutes,
    corrOrdersRoutes,
    usersRoutes,
  ],
};

const receiptRoutes = {
  path: "/receipt",
  name: "Закупки",
  title: "Закупки",
  icon: BriefcaseIcon,
  children: [
    supplyRoutes,
    providersRoutes,
    returnsToProvidersRoutes,
    acceptanceActsRoutes,
    ordersToProviderRoutes
  ],
};

const stockRoutes = {
  path: "/stock",
  name: "Склад",
  title: "Склад",
  icon: PackageIcon,
  children: [
    movingRoutes,
    enteringBalanceRoutes,
    stockPageRoutes,
    orderPickingRoute,
    writeOffsPageRoutes,
    misgradingsRoutes,
  ],
};

const referenceRoutes = {
  path: "/reference",
  name: "Справочники",
  title: "Справочники",
  icon: ListIcon,
  children: [
    brandsRoutes,
    brandSynonymsRoutes,
    priceRequestLogRoutes,
    crossRoutes,
    nomenclatureRoutes,
    characteristicsRoutes,
  ],
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  userNewRoutes,
  userOneRoutes,
  labelsRoutes,
  usersRoutes,
  ordersRoutes,
  orderOneRoutes,
  diadocRoutes,
  diadocRoutesByOrder,
  convertUpdRoutes,
  providersRoutes,
  providerNewRoutes,
  providerOneRoutes,
  brandsRoutes,
  brandSynonymsRoutes,
  nomenclatureRoutes,
  characteristicsRoutes,
  priceRequestLogRoutes,
  crossRoutes,
  crossRoutesUpload,
  supplyRoutes,
  supplyNewRoutes,
  supplyOneRoutes,
  enteringBalanceRoutes,
  enteringBalanceNewRoutes,
  enteringBalanceOneRoutes,
  corrOrdersRoutes,
  corrOrderNewRoutes,
  corrOrdersOneRoutes,
  returnsToProvidersRoutes,
  returnsToProvidersNewRoutes,
  returnsToProvidersOneRoutes,
  returnsCustomersRoutes,
  returnsCustomerNewRoutes,
  returnsCustomerOneRoutes,
  movingRoutes,
  movingNewRoutes,
  movingOneRoutes,
  stockPageRoutes,
  orderPickingRoute,
  orderPickingOneRoute,
  writeOffNewRoutes,
  writeOffOneRoutes,
  writeOffsPageRoutes,
  misgradingsRoutes,
  misgradingNewRoutes,
  misgradingOneRoutes,
  analiticsRoutes,
  acceptanceActNewRoutes,
  acceptanceActOneRoutes,
  acceptanceActsRoutes,
  ordersToProviderRoutes,
  orderToProviderNewRoutes,
  orderToProviderOneRoutes,
];

// Auth specific routes
export const page = [signInRoutes];

// All routes
const exportDefault = [
  dashboardRoutes,
  salesRoutes,
  receiptRoutes,
  stockRoutes,
  referenceRoutes,
  analiticsRoutes,
  labelsRoutes,
  diadocRoutes,
  convertUpdRoutes,
];
export default exportDefault;
