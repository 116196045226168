import { axiosInstance } from "./api";

export const getAnaliticsPrice = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/analiticsPrice.get", { params });
  return res.data;
};

export const getAnaliticsStock = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
    "sort[by]": props.sort?.by,
    "sort[dir]": props.sort?.dir,
  };

  const res = await axiosInstance.get("/analiticsStock.get", { params });
  return res.data;
};

export const generateAnaliticsStock = async (props) => {
  const res = await axiosInstance.post("/analiticsStock.generate", props);
  return res.data;
};

export const getProgress = async (props) => {
  const res = await axiosInstance.post("/analiticsStock.getProgress", props);
  return res.data;
};

export const updateBestPrice = async (props) => {
  const res = await axiosInstance.post(
    "/analiticsStock.updateBestPrice",
    props
  );
  return res.data;
};

export const downloadAnaliticsStock = async (props) => {
  const res = await axiosInstance.post("/analiticsStock.download", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getNomenclatureInReserve = async (props) => {
  const res = await axiosInstance.get(
    "/analiticsStock.getNomenclatureInReserve",
    { params: props }
  );
  return res.data;
};

export const getSalaryReport = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
    "sort[by]": props.sort?.by,
    "sort[dir]": props.sort?.dir,
  };

  const res = await axiosInstance.get("/salaryReport.get", { params });
  return res.data;
};

export const generateSalaryReport = async (props) => {
  const res = await axiosInstance.post("/salaryReport.generate", props);
  return res.data;
};

export const getProgressSalaryReport = async () => {
  const res = await axiosInstance.get("/salaryReport.getProgress");
  return res.data;
};

export const getAnaliticsSale = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
    "sort[by]": props.sort?.by,
    "sort[dir]": props.sort?.dir,
  };

  const res = await axiosInstance.get("/analiticsSale.get", { params });
  return res.data;
};

export const generateAnaliticsSale = async () => {
  const res = await axiosInstance.post("/analiticsSale.generate");
  return res.data;
};

export const getProgressAnaliticsSale = async () => {
  const res = await axiosInstance.get("/analiticsSale.getProgress");
  return res.data;
};

export const getAnaliticsReceivables = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
    "sort[by]": props.sort?.by,
    "sort[dir]": props.sort?.dir,
  };

  const res = await axiosInstance.get("/analiticsReceivables.get", { params });
  return res.data;
};

export const generateAnaliticsReceivables = async () => {
  const res = await axiosInstance.post("/analiticsReceivables.generate");
  return res.data;
};

export const getProgressAnaliticsReceivables = async () => {
  const res = await axiosInstance.get("/analiticsReceivables.getProgress");
  return res.data;
};

export const getLastUpdateAnaliticsReceivables = async () => {
  const res = await axiosInstance.get(
    "/analiticsReceivables.getDateLastUpdate"
  );
  return res.data;
};

export const downloadAnaliticsReceivables = async (props) => {
  const res = await axiosInstance.post(
    "/analiticsReceivables.download",
    props,
    {
      responseType: "blob",
    }
  );
  return res.data;
};
