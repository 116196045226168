import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";

import { getProviders } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";

import { tableColumns } from "./tableData";
import "./providers.scss";

export const ProvidersPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [providers, setProviders] = useState([]);
  const [providersCount, setProvidersCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [price, setPrice] = useState(query.get("price") ? 1 : 0);

  const getAndSetProviders = () => {
    getProviders({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      price,
    })
      .then((res) => {
        setProviders(res.items);
        setProvidersCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);

    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (price) {
      url.append("price", price);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    getAndSetProviders();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, price]);

  const checkboxHandler = (e) => {
    switch (e.target.name) {
      case "price":
        setPrice(e.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setLoading(true);
    getAndSetProviders();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/receipt/providers/${row.id}`);
    },
  };

  const searchHandler = () => {
    if (!search) return;
    setLoading(true);
    getAndSetProviders();
  };

  return (
    <Container fluid className="p-0 providers">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Поставщики</h1>
          <Form className="mb-3">
            <Row>
              <Col md="4">
                <InputGroup>
                  <Input
                    name="search"
                    placeholder="По наименованию, ИНН"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button color="primary" onClick={searchHandler}>
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
              <Col md="4">
                <Label check>
                  <Input
                    type="checkbox"
                    name="price"
                    onChange={checkboxHandler}
                  />
                  Только с прайсами
                </Label>
              </Col>
            </Row>
          </Form>

          <Button
            onClick={() => history.push("/receipt/providers/new")}
            color="primary"
          >
            Добавить поставщика
          </Button>
        </CardBody>
      </Card>
      <BootstrapTable
        keyField="id"
        data={providers}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={providersCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
