import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";

import { validHandler } from "utils/validation";
import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import {
  addSupplyProduct,
  getCountry,
  updateSupplyProduct,
} from "services/apiSupply";

const VALID = ["nomenclature", "summ", "count"];
const VALID_BAILOR = ["nomenclature", "count"];

export const AddProductModal = ({
  product = null,
  setProduct,
  orderToProviderId,
  openModal,
  setOpenModal,
  setProducts,
  provider,
}) => {
  const [article, setArticle] = useState("");
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [summ, setSumm] = useState("");
  const [count, setCount] = useState(1);
  const [nomenclature, setNomenclature] = useState(null);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);

  const summInputHandler = (e) => {
    const summ = e.target.value.replace(",", ".").replace(" ", "");

    if (summ === "" || /^[0-9\b\.]+$/.test(summ)) {
      setSumm(summ);

      setPrice(((parseFloat(summ) * 100) / count || 1) / 100);
    }
  };

  const priceInputHandler = (e) => {
    // const price = e.target.value;
    // if (price === "" || /^[0-9\b]+$/.test(price)) {
    //   setPrice(price);
    // }
  };

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);

      setPrice(((parseFloat(summ) * 100) / count || 1) / 100);
    }
  };

  const addProductHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler(
      {
        nomenclature,
        summ,
        count,
      },
      VALID
    );

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    addSupplyProduct({
      nomenclature,
      orderToProviderId,
      price,
      summ,
      count,
    })
      .then((res) => {
        setProducts(res.products);
        setArticle("");
        setNomenclature(null);
        setBrand("");
        setName("");
        setPrice("");
        setSumm("");
        setCount("");
        setProduct(null);
        toast.success("Товар успешно добавлен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => {
        setNeedValid(false);
        setOpenModal(false);
        setLoading(false);
      });
  };

  const updateProductHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler(
      {
        nomenclature,
        summ,
        count,
      },
      VALID
    );

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    updateSupplyProduct({
      id: product.id,
      nomenclature: {
        id: nomenclature,
      },
      orderToProviderId,
      price,
      summ,
      count,
    })
      .then((res) => {
        setProducts(res.products);
        setArticle("");
        setNomenclature(null);
        setBrand("");
        setName("");
        setPrice("");
        setSumm("");
        setCount("");
        toast.success("Товар успешно сохранен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        setNeedValid(false);
        setOpenModal(false);
      });
  };

  useEffect(() => {
    if (!product) return;
    setArticle(product.article);
    setNomenclature(product.nomenclature?.id);
    setBrand(product.brand);
    setName(product.name);
    setPrice(product.price);
    setSumm(product.summ);
    setCount(product.count);
  }, [product]);

  useEffect(() => {
    const { errors } = validHandler(
      {
        nomenclature,
        summ,
        count,
      },
      VALID
    );
    setErrors(errors);
    // eslint-disable-next-line
  }, [article, brand, summ, name, count]);

  const selectedBrand = (brand) => {
    setBrand(brand?.name);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">
          Добавить товар в заказ поставщику
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>

      {loading && <MySpinner overlay />}

      <ModalBody className="m-3">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Артикул</Label>

              <AutocompleteArticle
                article={article}
                brand={brand}
                setArticle={setArticle}
                setBrand={({ id, name }) => setBrand(name)}
                setName={setName}
                setNomenclature={setNomenclature}
                setPrice={setPrice}
                needValid={needValid}
                errors={errors}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brand"
                placeholder="Бренд"
                value={brand}
                invalid={needValid && errors.hasOwnProperty("brand")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Наименование</Label>
              <Input
                name="name"
                placeholder="Наименование"
                onChange={(e) => setName(e.target.value)}
                value={name}
                invalid={needValid && errors.hasOwnProperty("name")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label>Кол-во</Label>
              <Input
                name="count"
                placeholder="Кол-во"
                onChange={countInputHandler}
                value={count}
                invalid={needValid && errors.hasOwnProperty("count")}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Сумма</Label>
              <Input
                name="summ"
                placeholder="Сумма"
                onChange={summInputHandler}
                value={summ}
                invalid={needValid && errors.hasOwnProperty("summ")}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Цена</Label>
              <Input
                name="price"
                placeholder="Цена"
                onChange={priceInputHandler}
                value={price}
                invalid={needValid && errors.hasOwnProperty("price")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          {product ? (
            <Button
              onClick={updateProductHandler}
              color="primary"
              className="button-wide"
            >
              Сохранить
            </Button>
          ) : (
            <Button
              onClick={addProductHandler}
              color="primary"
              className="button-wide"
            >
              Добавить
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
