import React, { useEffect, useState } from "react";

import { MySpinner } from "components/MySpinner";
import { useUser } from "features/Users/UserContext";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";

import { login } from 'services/api';

export const SignIn = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { setUser } = useUser();

  useEffect(() => {
    if (localStorage.getItem('accessToken') && localStorage.getItem('refreshToken')) {
      history.push('/');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setError('');
  }, [email, password]);

  const loginHandler = () => {
    setLoading(true);
    login({ email, password })
      .then(res => {
        if (res === 401) {
          setError('Неверный логин или пароль');
          return;
        }
        localStorage.setItem('accessToken', res.accessToken);
        localStorage.setItem('refreshToken', res.refreshToken);
        setUser(res.user);
        history.push('/');
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setLoading(false))
  };

  return (
    <>
      <div className="text-center">
        <h2>ЛИДЕР ТРАК</h2>
        <p className="lead">Вход в админку</p>
      </div>

      {error &&
        <Alert color="danger">
          {error}
        </Alert>
      }
      <Card>
        <CardBody>
          {loading && <MySpinner overlay />}

          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Введите ваш Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Введите ваш пароль"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormGroup>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" onClick={loginHandler}>
                  Войти
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </>
  );
};



